<template>
  <div class="page">
    <app-header
      :child-header-class="currentViewMode === 'card' ? 'container-fluid' : 'container'"
      is-child-header-left-actions
      add-new-content-status
      justify-between-class
    >
      <div class="d-flex align-items-center">
        <div class="button-group">
          <div class="button-group__item dropdown">
            <label
              class="button button--shadow button--type-icon size-sm d-flex align-items-center"
              :class="{'active': isDropdown === 'perPageDropdown' }"
              @click="dropdownAction('perPageDropdown')"
            >
              <svg
                width="25"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    stroke="#ffffff"
                    fill="#ffffff"
                    id="svg_8"
                    d="m20.86045,13.91667c0,0.28222 -0.37434,0.51104 -0.83604,0.51104l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51104l0,0c0,-0.28223 0.37434,-0.51105 0.83604,-0.51105l15.04882,0c0.4617,0 0.83604,0.22882 0.83604,0.51105l0,0z"
                  />
                  <path
                    stroke="#ffffff"
                    fill="#ffffff"
                    id="svg_10"
                    d="m20.86045,4.58334c0,0.28223 -0.37434,0.51105 -0.83604,0.51105l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51105l0,0c0,-0.28223 0.37434,-0.51105 0.83604,-0.51105l15.04882,0c0.4617,0 0.83604,0.22882 0.83604,0.51105l0,0z"
                  />
                  <path
                    stroke="#ffffff"
                    fill="#ffffff"
                    id="svg_11"
                    d="m20.86045,9.41667c0,0.28223 -0.37434,0.51105 -0.83604,0.51105l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51105l0,0c0,-0.28223 0.37434,-0.51104 0.83604,-0.51104l15.04882,0c0.4617,0 0.83604,0.22881 0.83604,0.51104l0,0z"
                  />
                </g>
              </svg>
            </label>
            <div
              class="dropdown__menu left dropdown__menu--per-page"
              v-if="isDropdown === 'perPageDropdown'"
              v-on-clickaway="hideDropdown"
            >
              <div
                :class="[ perPage === option.value ? 'dropdown__menu-item active' : 'dropdown__menu-item']"
                v-for="option in pageOptions"
                :key="option.value"
                @click="pageOptionsSelected(option.value)"
              >
                {{ option.text }}
              </div>
            </div>
          </div>

          <div class="button-group__item">
            <div class="search-box">
              <span
                v-if="filterText"
                class="icon icon-reset"
                @click="resetFilter"
              >
                <icon-container
                  name="reset"
                  view-box="0 0 20 20"
                >
                  <icon-reset />
                </icon-container>
              </span>
              <span
                @click="doFilter()"
                class="icon-search"
              >
                <icon-container
                  name="search"
                  :width="14"
                  :height="14"
                  color="#727d92"
                  view-box="0 0 14 14"
                >
                  <icon-search />
                </icon-container>
              </span>
              <input
                type="text"
                ref="searchBox"
                class="form-control"
                v-sanitize
                :placeholder="$t('search_placeholder')"
                v-model="filterText"
                @keydown="doFilter()"
              >
            </div>
          </div>

          <div class="button-group__item dropdown">
            <button
              class="button button--shadow button--type-icon size-sm d-flex align-items-center"
              :class="{'type-active': subType }"
              @click="dropdownAction('typeDropdown')"
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.60074 3.22443C9.11201 3.22443 8.71692 2.82843 8.71692 2.34061V1.0657C8.71692 0.577703 9.11201 0.181885 9.60074 0.181885C10.0895 0.181885 10.4846 0.577703 10.4846 1.0657V2.34061C10.4846 2.82843 10.0895 3.22443 9.60074 3.22443ZM9.60074 15.7233C9.11201 15.7233 8.71692 15.3273 8.71692 14.8393V6.3697C8.71692 5.8817 9.11201 5.48588 9.60074 5.48588C10.0895 5.48588 10.4846 5.8817 10.4846 6.3697V14.8393C10.4846 15.3273 10.0895 15.7233 9.60074 15.7233Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.89957 10.4193C2.41084 10.4193 2.01575 10.0235 2.01575 9.53552V1.0657C2.01575 0.577885 2.41084 0.181885 2.89957 0.181885C3.38829 0.181885 3.78338 0.577885 3.78338 1.0657V9.53552C3.78338 10.0235 3.38829 10.4193 2.89957 10.4193ZM2.89957 15.7233C2.41084 15.7233 2.01575 15.3273 2.01575 14.8395V13.5646C2.01575 13.0766 2.41084 12.6808 2.89957 12.6808C3.38829 12.6808 3.78338 13.0766 3.78338 13.5646V14.8395C3.78338 15.3273 3.38829 15.7233 2.89957 15.7233Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.3022 9.35061C15.8134 9.35061 15.4183 8.95479 15.4183 8.46679V1.0657C15.4183 0.577885 15.8134 0.181885 16.3022 0.181885C16.7909 0.181885 17.186 0.577885 17.186 1.0657V8.46679C17.186 8.95479 16.7909 9.35061 16.3022 9.35061ZM16.3022 15.7233C15.8134 15.7233 15.4183 15.3273 15.4183 14.8393V12.537C15.4183 12.049 15.8134 11.6532 16.3022 11.6532C16.7909 11.6532 17.186 12.049 17.186 12.537V14.8393C17.186 15.3273 16.7909 15.7233 16.3022 15.7233Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.89958 10.4194C2.27485 10.4194 1.76776 10.9265 1.76776 11.5505C1.76776 12.1736 2.27485 12.6809 2.89958 12.6809C3.52285 12.6809 4.02994 12.1736 4.02994 11.5505C4.02994 10.9265 3.52285 10.4194 2.89958 10.4194ZM2.89958 14.4485C1.3003 14.4485 0.00012207 13.1482 0.00012207 11.5505C0.00012207 9.95197 1.3003 8.65161 2.89958 8.65161C4.49721 8.65161 5.79758 9.95197 5.79758 11.5505C5.79758 13.1482 4.49721 14.4485 2.89958 14.4485Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.3021 9.35058C15.6788 9.35058 15.1717 9.85767 15.1717 10.4809C15.1717 11.1382 15.669 11.6531 16.3021 11.6531C16.9366 11.6531 17.4339 11.1382 17.4339 10.4809C17.4339 9.85767 16.9268 9.35058 16.3021 9.35058ZM16.3021 13.4208C14.7042 13.4208 13.4041 12.102 13.4041 10.4809C13.4041 8.88313 14.7042 7.58276 16.3021 7.58276C17.9011 7.58276 19.2015 8.88313 19.2015 10.4809C19.2015 12.102 17.9011 13.4208 16.3021 13.4208Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.60076 3.22436C8.97749 3.22436 8.4704 3.73145 8.4704 4.35472C8.4704 4.97854 8.97749 5.48582 9.60076 5.48582C10.224 5.48582 10.7311 4.97854 10.7311 4.35472C10.7311 3.73145 10.224 3.22436 9.60076 3.22436ZM9.60076 7.25345C8.00312 7.25345 6.70276 5.95309 6.70276 4.35472C6.70276 2.75691 8.00312 1.45654 9.60076 1.45654C11.1986 1.45654 12.4988 2.75691 12.4988 4.35472C12.4988 5.95309 11.1986 7.25345 9.60076 7.25345Z"
                  fill="white"
                />
              </svg>
            </button>
            <div
              class="dropdown__menu right dropdown__menu--sub-type"
              v-if="isDropdown === 'typeDropdown'"
              v-on-clickaway="hideDropdown"
            >
              <div
                :class="[ subTypeText === option.text ? 'dropdown__menu-item active' : 'dropdown__menu-item']"
                v-for="option in typeOptions"
                :key="option.value"
                @click="typeOptionsSelected(option.value)"
              >
                {{ option.text }}
              </div>
            </div>
          </div>
        </div>
        <dropdown
          class-name="filter"
          v-if="isVibonsAdmin"
          :filter-on="the_filter.only_active_customer_users"
        >
          <template slot="icon">
            <i
              class="fa fa-filter button button--type-icon size-sm d-flex align-items-center"
              style="cursor: pointer;color: rgb(114, 125, 146);"
            />
          </template>
          <template slot="body">
            <div class="d-block p-2">
              <div
                class="d-block"
              >
                <label
                  for="filter-only_active_customer_users"
                  class="checkbox"
                >
                  <input
                    type="checkbox"
                    id="filter-only_active_customer_users"
                    :true-value="true"
                    :false-value="false"
                    v-model="the_filter.only_active_customer_users"
                  >
                  {{ $t('only_active_customer_users') }}
                </label>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <template v-slot:right>
        <div class="d-flex flex-row-reverse">
          <div class="table-mode-type d-flex align-items-center">
            <label class="m-0">
              <input
                type="radio"
                v-model="currentViewMode"
                value="card"
              >
              <icon-container
                :width="40"
                :height="40"
                view-box="0 0 512 512"
                name="card"
                color="#727D92"
              >
                <icon-card />
              </icon-container>
            </label>
            <label class="m-0">
              <input
                type="radio"
                v-model="currentViewMode"
                value="list"
              >
              <icon-container
                :width="40"
                :height="40"
                view-box="0 0 512 512"
                name="list"
                color="#727D92"
              >
                <icon-list />
              </icon-container>
            </label>
          </div>
        </div>
      </template>
    </app-header>

    <div
      ref="containerBox"
      id="containerBox"
      :class="currentViewMode === 'card' ? 'container-fluid' : 'container'"
    >
      <div class="vuetable-wrapper">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :view-mode="currentViewMode"
          wrapper-class="vuetable-wrapper"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          :reactive-api-url="false"
          @vuetable:pagination-data="onPaginationData"
          @viewModeStatus="setViewMode"
        >
          <template
            v-if="currentViewMode === 'card'"
            slot="custom-block"
            slot-scope="props"
          >
            <li
              class="list-group-item card card--catalog card--catalog-item"
              :key="props.rowIndex"
              :style="{'--i': props.rowIndex}"
              @mouseover="plusActionToggle(props.rowIndex)"
              @mouseleave="hidePlusAction"
            >
              <button
                class="card-button"
                @click="previewContentItem(props.rowData)"
              >
                <div
                  v-if="isItemNew(props.rowData.created_at)"
                  class="ribbon"
                >
                  <span>{{ $t('new') }}</span>
                </div>
                <figure
                  class="card__media"
                  :style="'background-image: url(' + props.rowData.thumb + ');'"
                />
                <div class="card__body">
                  <div class="card__content">
                    <span
                      class="card__info"
                      v-if="subtypeCondition(props.rowData)"
                    >{{ $helpers.getSubTypeName(props.rowData.sub_type) }}</span>
                    <h3 class="card__title">
                      <span v-if="titleCondition(props.rowData)">{{ props.rowData.name }}</span>
                    </h3>
                  </div>
                  <div class="card__bottom">
                    <div class="card__bottom__left">
                      <small class="card__info">{{ props.rowData.info }}</small>
                    </div>
                  </div>
                </div>
              </button>
              <div class="card__footer">
                <span
                  class="button-upload"
                  v-if="isVibonsAdmin"
                  @click="uploadImageContent(props.rowData)"
                >
                  <icon-container
                    name="upload"
                    view-box="0 0 334 334"
                    height="24"
                    width="24"
                    color="#fa314a"
                    :is-icon-class="true"
                  >
                    <icon-upload />
                  </icon-container>
                </span>
                <div
                  class="dropdown"
                  @mouseover="dropdownToggle(props.rowIndex)"
                  @mouseleave="hideToggleDropdown"
                >
                  <span class="card--actions dropdown__toggle">
                    <icon-container
                      name="threedotsvertical"
                      :width="5"
                      :height="22"
                      class="threedots"
                      view-box="0 0 5 22"
                    >
                      <icon-threedotsvertical />
                    </icon-container>
                  </span>
                  <div
                    class="dropdown__menu"
                    :ref="`dropdownBox${props.rowIndex}`"
                    v-show="dropdownIndex === props.rowIndex"
                    v-on-clickaway="hideToggleDropdown"
                  >
                    <span
                      class="dropdown__button button"
                      :disabled="!canEdit && !!props.rowData.is_public"
                      @click="onAction('edit-item', props.rowData)"
                    >
                      <icon-container
                        :width="18"
                        :height="18"
                        name="edit"
                        view-box="0 0 24 24"
                        color="#727d92"
                        :is-icon-class="false"
                      >
                        <icon-edit />
                      </icon-container>
                      {{ $t('edit') }}
                    </span>
                    <span
                      class="dropdown__button button"
                      :disabled="!canEdit && !!props.rowData.is_public"
                      @click="onAction('delete-item', props.rowData)"
                    >
                      <icon-container
                        :width="18"
                        :height="18"
                        name="trash"
                        view-box="0 0 24 24"
                        color="#727d92"
                        :is-icon-class="false"
                      >
                        <icon-trash />
                      </icon-container>
                      {{ $t('delete') }}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </template>
          <template
            slot="icontype-slot"
            slot-scope="props"
          >
            <span
              class="icon__item"
              v-html="$helpers.iconType(props.rowData.sub_type)"
            />
          </template>
          <template
            slot="name-slot"
            slot-scope="props"
          >
            <div
              class="content-item"
              @click="previewContentItem(props.rowData)"
            >
              {{ props.rowData.name }}
            </div>
          </template>
          <template
            slot="subtype-slot"
            slot-scope="props"
          >
            {{
              $helpers.getSubTypeName(props.rowData.sub_type)
            }}
          </template>
          <template
            slot="action-slot"
            slot-scope="props"
          >
            <button
              class="ui button edit"
              :disabled="!canEdit && !!props.rowData.is_public"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <button
              class="ui button delete"
              :disabled="!canEdit && !!props.rowData.is_public"
              @click="onAction('delete-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading, 'center': currentViewMode === 'card', 'left': currentViewMode !== 'card' }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
            :css="css.paginationInfo"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCard from '@/components/icons/Card.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconList from '@/components/icons/List.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconThreedotsVertical from '@/components/icons/ThreedotsVertical.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconUpload from '@/components/icons/Upload.vue';
import moment from 'moment';
import { directive as onClickaway } from 'vue-clickaway';

import { API_CONTENT_DATATABLES, API_GET_CONTENT_INFO, API_SERVER_TIME_FORMAT } from '@/helpers/config';
import myTableMixin from '@/helpers/myTableMixin.vue';
import Vuetable from '../../components/CustomVuetable/Vuetable.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';
import VuetablePaginationInfo from '../../components/CustomVuetable/VuetablePaginationInfo.vue';

console.log('Contents index');

export default {
  name: 'Contents',
  mixins: [myTableMixin],
  data() {
    return {
      currentViewMode: 'card',
      plusActionIndex: null,
      pageOptions: this.cardPageOptions,
      dropdownIndex: null,
      isDropdown: null,
      filterText: '',
      searchFor: null,
      perPage: 15,
      subType: null,
      subTypeText: null,
      the_filter: {
        only_active_customer_users: false,
      },
      moreParams: {
        exclude: 'Z-Flipbook',
      },
    };
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    the_filter: {
      handler(filter) {
        if (filter.only_active_customer_users) {
          this.$set(this.moreParams, 'customer_id', this.$store.state.auth.customerId);
        } else {
          this.$delete(this.moreParams, 'customer_id');
        }
      },
      immediate: true,
      deep: true,
    },
    currentViewMode(newValue) {
      if (newValue === 'card') {
        this.pageOptions = this.cardPageOptions;
      } else {
        this.pageOptions = this.listPageOptions;
      }
      setTimeout(() => this.calculateNumItemPerPage(), 400);
    },

    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Contents') {
        this.$refs.myvuetable.reload();
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },

    subType(newValue) {
      if (newValue) {
        this.typeOptions.forEach((type) => {
          if (type.value === newValue) {
            this.subTypeText = type.text;
          }
        });
      }
      if (newValue === null) {
        this.subTypeText = null;
      }
    },
  },

  computed: {
    apiUrl() {
      if (API_CONTENT_DATATABLES) {
        return `${API_CONTENT_DATATABLES}`;
      }
      return null;
    },

    canEdit() {
      if (this.isVibonsAdmin || this.$store.state.auth.userInfo.role === 'customer-admin') {
        return true;
      }
      return false;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    typeOptions() {
      return [
        { value: null, text: this.$t('reset_filter') },
        { value: 3, text: this.$t('internal_video') },
        { value: 9, text: this.$t('flipbook') },
        { value: 1, text: this.$t('infographic') },
        { value: 44, text: this.$t('scorm') },
        { value: 5, text: this.$t('internal_article') },
        { value: 2, text: this.$t('external_video') },
        { value: 4, text: this.$t('external_article') },
        { value: 10, text: this.$t('quiz') },
        { value: 11, text: this.$t('survey') },
        { value: 12, text: this.$t('checklist') },
        { value: 7, text: this.$t('book_suggestion') },
        { value: 6, text: this.$t('quotes') },
        { value: 37, text: this.$t('podcast') },
      ];
    },

    listPageOptions() {
      return [
        { text: `${this.$t('view')} 10`, value: 10 },
        { text: `${this.$t('view')} 20`, value: 20 },
        { text: `${this.$t('view')} 50`, value: 50 },
        { text: `${this.$t('view')} 100`, value: 100 },
      ];
    },

    cardPageOptions() {
      return [
        { text: `${this.$t('view')} 3`, value: 3 },
        { text: `${this.$t('view')} 6`, value: 6 },
        { text: `${this.$t('view')} 9`, value: 9 },
        { text: `${this.$t('view')} 12`, value: 12 },
      ];
    },

    fields() {
      return [
        {
          name: 'icontype-slot',
          title: '',
          width: '5%',
        },
        {
          name: 'name-slot',
          title: this.$t('content_name'),
          width: '55%',
          sortField: 'name',
        },
        {
          name: 'subtype-slot',
          title: this.$t('type'),
          width: '15%',
          sortField: 'sub_type',
        },
        {
          name: 'info',
          title: this.$t('info'),
          width: '15%',
        },
        {
          name: 'action-slot',
          title: this.$t('actions'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
        },
      ];
    },
  },

  components: {
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'icon-container': IconContainer,
    'icon-edit': IconEdit,
    'icon-trash': IconTrash,
    'icon-card': IconCard,
    'icon-list': IconList,
    'icon-upload': IconUpload,
    'icon-reset': IconReset,
    'icon-search': IconSearch,
    'icon-threedotsvertical': IconThreedotsVertical,
    Dropdown,
  },

  mounted() {
    this.$store.commit('modal/setJourneyDetail', null);
    this.$store.commit('modal/setJourneyItemDetail', null);
    this.$store.commit('modal/setJourneyId', null);
    this.calculateNumItemPerPage();
    this.pageOptions = this.cardPageOptions;

    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.calculateNumItemPerPage);
    // });
    this.$root.$on('editContentItem', (itemId) => {
      if (this.$refs && this.$refs.myvuetable) {
        const tableData = this.$refs.myvuetable.getData();
        const found = [...tableData].find(item => item.id === itemId);
        if (found) {
          this.$store.commit('modal/togglePreviewModal', false);
          setTimeout(() => this.onAction('edit-item', found), 1000);
        }
      }
    });
    // this.calculateNumItemPerPage();
  },

  activated() {
    this.$store.commit('modal/setJourneyId', null);
    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.calculateNumItemPerPage);
    // });
  },

  // deactivated() {
  //   window.removeEventListener('resize', this.calculateNumItemPerPage);
  // },

  // beforeDestroy() {
  //   window.removeEventListener('resize', this.calculateNumItemPerPage);
  // },

  methods: {

    titleCondition(data) {
      if (data) {
        const type = data.sub_type;
        if (type && (type === 'BOOK_SUGGESTION' || type === 'INFOGRAPHIC' || type === 'FLIPBOOK' || type === 'QUOTES')) {
          return false;
        }
        return true;
      }
      return false;
    },

    subtypeCondition(data) {
      if (data) {
        const type = data.sub_type;
        if (type && type === 'QUOTES') {
          return false;
        }
        return true;
      }
      return false;
    },
    uploadImageContent(item) {
      if (this.isVibonsAdmin) {
        const obj = {
          modalType: 'modalUploadImageContent',
          contentId: item.id,
          launchedFrom: 'Contents',
        };
        this.$helpers.toggleModal(obj);
        this.$store.commit('modal/setJourneyItemDetail', item);
        this.$store.commit('modal/setJourneyItemID', item.id);
      }
    },
    maxCardInARow() {
      if (this.$refs.containerBox) {
        return Math.floor((this.$refs.containerBox.offsetWidth - 30) / 255);
      }
      return 4; // default row size for card mode
    },

    calculateNumItemPerPage(perpage) {
      let perPageForViewMode = null;
      const maxCardInARow = this.maxCardInARow();

      if (perpage === 0 || perpage === undefined || Number.isNaN(Number(perpage))) {
        if (this.currentViewMode === 'card') {
          perPageForViewMode = 3; // default value for card mode
        } else {
          perPageForViewMode = 10; // default value for listing mode
        }
      } else {
        perPageForViewMode = perpage;
      }

      if (this.currentViewMode === 'card') {
        perPageForViewMode *= maxCardInARow;
      }

      this.perPage = perPageForViewMode;
    },

    setViewMode(value) {
      this.currentViewMode = value;
    },

    dropdownAction(value) {
      this.isDropdown = value;
    },

    hideDropdown() {
      this.isDropdown = null;
    },

    hideToggleDropdown() {
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    plusActionToggle(index) {
      this.plusActionIndex = index;
    },

    hidePlusAction() {
      this.plusActionIndex = null;
    },

    dropdownToggle(index) {
      clearTimeout(this.timer);
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];
          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('right');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('bottom');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 1);
    },

    isItemNew(itemDate) {
      const today = moment().startOf('day');
      const theDate = moment(itemDate, API_SERVER_TIME_FORMAT);
      const diffDays = today.diff(theDate, 'days', false);
      if (diffDays <= 15) {
        return true;
      }
      return false;
    },

    pageOptionsSelected(value) {
      this.calculateNumItemPerPage(value);
      this.hideDropdown();
    },

    typeOptionsSelected(value) {
      this.subType = value;
      this.moreParams.subtype_id_filter = value;
      this.hideDropdown();
      this.$refs.myvuetable.refresh();
    },

    previewContentItem(item) {
      const obj = {
        id: item.options,
        launchedFrom: 'Contents',
      };
      this.$store.commit('modal/togglePreviewModal', obj);
    },

    onAction(action, data) {
      const itemId = data.options;
      this.idHolder = itemId;
      this.apiUrlHolder = API_GET_CONTENT_INFO;
      if (!itemId) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(itemId)) {
        this.snotifyQueueList.push(itemId);
        this.$snotify.confirm(this.$t('content_delete_msg_body', { name: data.name }), this.$t('content_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      } else if (action === 'edit-item') {
        console.log(`editing item: ${data.name}`);
        const obj = {
          modalType: data.has_flipbook_editable ? 'modalFlipbookEditable' : 'modalCreateContent',
          contentId: itemId,
          tabPane: 'mainTab',
          pageToRefresh: 'Contents',
          launchedFrom: 'Contents',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },

    doFilter() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.moreParams.search = this.filterText;
        this.$refs.myvuetable.refresh();
      }, 300);
    },

    resetFilter() {
      this.filterText = '';
      this.moreParams.search = this.filterText;
      this.$refs.myvuetable.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  display: inline-block;
  vertical-align: middle;
}
.filterform {
  display: inline-block;
}
.filtericon {
  font-size: 1.5rem;
  vertical-align: middle;
  margin: 2px;
  cursor: pointer;
  display: inline-block;
  border-radius: 2px;
  background-color: #f7f8fc;
  border: 0;
  justify-content: center;
  height: 30px;
  color: rgba(60, 61, 63, 0.952);
  box-shadow: 0 0.25rem 0.125rem 0 rgba(0, 0, 0, 0.05);
  svg {
    margin: 2px;
  }
}

button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.vuetable-wrapper {
  position: relative;
  opacity: 1;
  margin-bottom: 50px;
}
.pagination__info {
  display: block;
}
.pagination__items {
  width: 100%;
  text-align: center;
}
.enter {
  width: 0;
}
.enter-to {
  width: 100%;
}
.slide-enter-active {
  position: absolute;
}

.leave {
  width: 100%;
}
.leave-to {
  width: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 50ms ease-in-out;
}

.blur {
  filter: blur(3px);
}
.center {
  text-align: center;
}

::v-deep .button--shadow {
  background: #727d92 !important;
}
::v-deep .dropdown__menu.per-page {
  left: -190% !important;
}
.search-box {
  position: relative;
  width: 100%;
  input {
    background: #f2f2f2;
    padding-left: 20px;
    height: 36px;
    color: #333;
    min-width: 150px;
    max-width: 300px;
  }

}
.icon-search {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
.icon-reset {
  position: absolute;
  left: 5px;
  top: 15px;
  cursor: pointer;

  svg {
    width: 13px;
    height: 13px;
  }
}
.perpage {
  left: -230% !important;
  height: 320px;
  min-width: 170px;
  white-space: nowrap;
  overflow-y: auto;
}

.button--type-icon.type-active {
  box-shadow: 0 4px 10px 0 rgba(45, 207, 133, 0.4);
  background-color: #2dcf85 !important;
  color: #fff;

  svg path {
    fill: #fff;
    stroke: #fff;
  }
}
.button--type-icon {
  svg path {
    fill: #fff;
    stroke: #fff;
  }
}
.button-group__item.dropdown {
  z-index: 0;
}
.content-item {
  width: 100%;
  height: 100%;
  cursor:pointer;
}
.pointer-action {
  cursor:pointer;
}

figure {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background-size: cover;
  background-position-x: center!important;
  background-position-y: top!important;
  background: #efefef;
}
.button-upload {
  position: absolute;
  left: 40px;
  top: 10px;
}
::v-deep span.custom-dropdown__btn {
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(114, 125, 146, 0.2);
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
  }
::v-deep .ribbon {
  z-index: 5;
}
</style>
