var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _vm.pageIsLoading
            ? _c("div", { staticClass: "page-loader" }, [
                _c("div", { staticClass: "page-is-loading" }),
              ])
            : _vm._e(),
          !_vm.pageIsLoading
            ? _c("div", { staticClass: "modal-content" }, [
                _c("header", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.editMode
                            ? _vm.$t("edit_journey")
                            : _vm.$t("create_new_journey")
                        ) +
                        " "
                    ),
                  ]),
                  _c("button", {
                    staticClass: "close",
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s("&times;") },
                    on: { click: _vm.closeModal },
                  }),
                ]),
                _c("nav", { staticClass: "navigation navigation--secondary" }, [
                  _c("div", { staticClass: "container" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "navigation__list nav",
                        attrs: { id: "contentCreateTab", role: "tablist" },
                      },
                      [
                        _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: { active: _vm.tabPane === "mainTab" },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("mainTab")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("main_tab")) + " ")]
                          ),
                        ]),
                        _vm.canBeReschedule
                          ? _c("li", { staticClass: "navigation__item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "navigation__link",
                                  class: {
                                    active:
                                      _vm.tabPane === "rescheduleJourneyTab",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.tabNavigation(
                                        "rescheduleJourneyTab"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("reschedule_tab")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.duplicated_journey_id
                          ? _c("li", { staticClass: "navigation__item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "navigation__link",
                                  class: {
                                    active:
                                      _vm.tabPane === "reminderSettingsTab",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.tabNavigation(
                                        "reminderSettingsTab"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("reminder_tab")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "advancedSettingsTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation(
                                    "advancedSettingsTab"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("advanced_tab")) + " ")]
                          ),
                        ]),
                        _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "assignProfileTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("assignProfileTab")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("assign_profile_tab")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tabPane === "rescheduleJourneyTab" &&
                                  !_vm.showCalander,
                                expression:
                                  "tabPane === 'rescheduleJourneyTab' && !showCalander",
                              },
                            ],
                            class: [
                              "panel-detail",
                              _vm.showPanel ? "active" : "",
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "icon-setting align-items-center",
                                on: {
                                  click: function ($event) {
                                    _vm.showPanel = !_vm.showPanel
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "setting",
                                      "view-box": "0 0 12 12",
                                      widht: 20,
                                      height: 20,
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-setting")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tabPane === "rescheduleJourneyTab",
                                expression:
                                  "tabPane === 'rescheduleJourneyTab'",
                              },
                            ],
                            class: [
                              "panel-detail",
                              _vm.showCalander ? "active" : "",
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "icon-setting align-items-center",
                                on: {
                                  click: function ($event) {
                                    _vm.showCalander = !_vm.showCalander
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "calander",
                                      "view-box": "0 0 20 20",
                                      widht: 20,
                                      height: 20,
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-calander")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      class: { disabled: _vm.pageIsLoading },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tabPane === "mainTab",
                              expression: "tabPane === 'mainTab'",
                            },
                          ],
                          staticClass: "tab-pane",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "contentTitle" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("journey_name_uppercase"))
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.journeyName,
                                      expression: "journeyName",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|max:100",
                                      expression: "'required|max:100'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-danger": _vm.errors.has("journey.name"),
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "journeyName",
                                    "data-vv-scope": "journey",
                                    "data-vv-name": "name",
                                    "data-vv-as": _vm.$t("journey_name"),
                                    placeholder: _vm.$t(
                                      "journey_name_placeholder"
                                    ),
                                    disabled:
                                      _vm.canNotEdit || _vm.pageIsLoading,
                                  },
                                  domProps: { value: _vm.journeyName },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.journeyName = $event.target.value
                                      },
                                      function ($event) {
                                        return _vm.updateName(
                                          $event.target.value
                                        )
                                      },
                                    ],
                                  },
                                }),
                                _vm.errors.has("journey.name")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first("journey.name")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "contentTitle" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("friendly_name")))]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.journeyNameFriendly,
                                      expression: "journeyNameFriendly",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "max:100",
                                      expression: "'max:100'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-danger":
                                      _vm.errors.has("journey.friendly"),
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "journeyNameFriendly",
                                    "data-vv-scope": "journey",
                                    "data-vv-name": "friendly",
                                    "data-vv-as": _vm.$t(
                                      "friendly_name_journey"
                                    ),
                                    placeholder: _vm.$t(
                                      "journey_friendly_placeholder"
                                    ),
                                    disabled: _vm.canNotEdit,
                                  },
                                  domProps: { value: _vm.journeyNameFriendly },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.journeyNameFriendly =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.errors.has("journey.friendly"),
                                        expression:
                                          "errors.has('journey.friendly')",
                                      },
                                    ],
                                    staticClass: "help is-danger",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first("journey.friendly")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-8" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group h-100 d-flex flex-column",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "name" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("description")))]
                                  ),
                                  _c("vue-editor", {
                                    attrs: {
                                      id: "cbody",
                                      "editor-toolbar": _vm.customToolbar,
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                  _vm.errors.has("journey.description")
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "journey.description"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "journeyLanguage" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("journey_language")))]
                                  ),
                                  _c("multi-select", {
                                    staticClass:
                                      "selectbox selectbox--secondary",
                                    attrs: {
                                      "track-by": "name",
                                      label: "value",
                                      "clear-on-select": "",
                                      "deselect-label": "",
                                      "select-label": "",
                                      options: _vm.languageOptions,
                                      disabled:
                                        _vm.canNotEdit || _vm.pageIsLoading,
                                    },
                                    model: {
                                      value: _vm.selectedLanguage,
                                      callback: function ($$v) {
                                        _vm.selectedLanguage = $$v
                                      },
                                      expression: "selectedLanguage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.items.length > 0,
                                  expression: "errors.items.length > 0",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert--card alert--error",
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function (error, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(" " + _vm._s(error.msg) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isPending || _vm.canNotEdit,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.editMode
                                      ? _vm.updateJourney()
                                      : _vm.createJourney()
                                  },
                                },
                              },
                              [
                                !_vm.isPending
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editMode
                                              ? _vm.$t("update_button")
                                              : _vm.$t("create_button")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.isPending
                                  ? _c("div", { staticClass: "lds-ellipsis" }, [
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.tabPane === "rescheduleJourneyTab"
                        ? _c("div", { staticClass: "tab-pane" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "collapse-transition",
                                  {
                                    attrs: {
                                      duration: 500,
                                      dimension: "height",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showCalander,
                                            expression: "showCalander",
                                          },
                                        ],
                                        staticClass: "col-12",
                                      },
                                      [
                                        _c("full-calendar", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showCalander,
                                              expression: "showCalander",
                                            },
                                          ],
                                          attrs: {
                                            "user-lang": _vm.userLang,
                                            "limit-date": false,
                                            "calender-events":
                                              _vm.calenderEvents,
                                            "preview-first-date":
                                              _vm.firstItemDate,
                                            "total-event-days":
                                              _vm.totalEventDays,
                                          },
                                          on: {
                                            setDateFromDrop:
                                              _vm.setDateFromDrop,
                                            isDragging: _vm.setDragging,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "collapse-transition",
                                  {
                                    attrs: {
                                      duration: 500,
                                      dimension: "height",
                                    },
                                  },
                                  [
                                    !_vm.showCalander
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "container-fluid",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row justify-content-between row-height",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass: "col-9",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-3 d-flex p-0",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.showPanel,
                                                                expression:
                                                                  "showPanel",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "swicth-label-head mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "send_email_short"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "swicth-label-head",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "send_push_notif_short"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "draggable-comp",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    handle: ".button-handle",
                                                    animation: 200,
                                                  },
                                                  on: {
                                                    start: function ($event) {
                                                      _vm.isDragging = true
                                                    },
                                                    end: function ($event) {
                                                      _vm.isDragging = false
                                                    },
                                                    change: _vm.cardItemMoved,
                                                  },
                                                  model: {
                                                    value: _vm.tempDataList,
                                                    callback: function ($$v) {
                                                      _vm.tempDataList = $$v
                                                    },
                                                    expression: "tempDataList",
                                                  },
                                                },
                                                "draggable-comp",
                                                _vm.dragOptions,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "transition-group",
                                                  {
                                                    staticClass:
                                                      "journey-preview-list",
                                                    attrs: {
                                                      name: "ilist",
                                                      tag: "ul",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.tempDataList,
                                                      function (item, index) {
                                                        return [
                                                          true
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "journey-preview-list__element",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "button-handle",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "icon-container",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: "handle",
                                                                              "view-box":
                                                                                "0 0 25 18",
                                                                              "is-icon-class": false,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "icon-handle"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  item &&
                                                                  item.type ===
                                                                    "journey_item" &&
                                                                  item.content
                                                                    .sub_type !==
                                                                    "LIVE_EVENT" &&
                                                                  item.content
                                                                    .sub_type !==
                                                                    "MEETING"
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "container-fluid",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-5 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item journey-preview-list__item--primary",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon__item",
                                                                                                domProps:
                                                                                                  {
                                                                                                    innerHTML:
                                                                                                      _vm._s(
                                                                                                        _vm.$helpers.iconType(
                                                                                                          item
                                                                                                            .content
                                                                                                            .sub_type
                                                                                                        )
                                                                                                      ),
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "title",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .content
                                                                                                  .name
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-4 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item",
                                                                                        class:
                                                                                          {
                                                                                            "is-editing":
                                                                                              _vm
                                                                                                .record
                                                                                                .id ===
                                                                                              item.id,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "date-picker",
                                                                                          {
                                                                                            ref: `datepicker${index}`,
                                                                                            refInFor: true,
                                                                                            staticClass:
                                                                                              "date",
                                                                                            attrs:
                                                                                              {
                                                                                                lang: _vm.userLang,
                                                                                                type: "datetime",
                                                                                                "value-type":
                                                                                                  "YYYY-MM-DD HH:mm:ss",
                                                                                                format:
                                                                                                  _vm.userLang ===
                                                                                                  "en"
                                                                                                    ? "MM.DD.YYYY / hh:mm A"
                                                                                                    : "DD.MM.YYYY / HH:mm",
                                                                                                "time-picker-options":
                                                                                                  {
                                                                                                    start:
                                                                                                      "08:00",
                                                                                                    step: "00:15",
                                                                                                    end: "23:45",
                                                                                                    format:
                                                                                                      _vm.userLang ===
                                                                                                      "en"
                                                                                                        ? "hh:mm A"
                                                                                                        : "HH:mm",
                                                                                                  },
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                (
                                                                                                  date,
                                                                                                  type
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    type,
                                                                                                    date,
                                                                                                    "activation_date"
                                                                                                  )
                                                                                                },
                                                                                              input:
                                                                                                (
                                                                                                  date
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    "manuel",
                                                                                                    date,
                                                                                                    "activation_date"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  item
                                                                                                    .content
                                                                                                    .activation_date,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      item.content,
                                                                                                      "activation_date",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "item.content.activation_date",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-calendar",
                                                                                                  },
                                                                                                slot: "icon-calendar",
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-clear",
                                                                                                  },
                                                                                                slot: "icon-clear",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "button-edit",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.showDatePicker(
                                                                                                    `datepicker${index}`
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "svg",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    width:
                                                                                                      "12",
                                                                                                    height:
                                                                                                      "12",
                                                                                                    viewBox:
                                                                                                      "0 0 12 12",
                                                                                                    fill: "none",
                                                                                                    xmlns:
                                                                                                      "http://www.w3.org/2000/svg",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "mask",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id: "mask0",
                                                                                                        "mask-type":
                                                                                                          "alpha",
                                                                                                        maskUnits:
                                                                                                          "userSpaceOnUse",
                                                                                                        x: "0",
                                                                                                        y: "0",
                                                                                                        width:
                                                                                                          "12",
                                                                                                        height:
                                                                                                          "12",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M0 0H11.9556V11.9291H0V0Z",
                                                                                                            fill: "white",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "g",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        mask: "url(#mask0)",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z",
                                                                                                            fill: "#374163",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm.showPanel,
                                                                                          expression:
                                                                                            "showPanel",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "col-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex justify-content-center aling-items-center mx-4",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_email_enabled,
                                                                                                          expression:
                                                                                                            "item.content.is_email_enabled",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "checkbox",
                                                                                                        id: `sendEmail${index}`,
                                                                                                        "true-value": 1,
                                                                                                        "false-value": 0,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        checked:
                                                                                                          Array.isArray(
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_email_enabled
                                                                                                          )
                                                                                                            ? _vm._i(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                                null
                                                                                                              ) >
                                                                                                              -1
                                                                                                            : _vm._q(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                                1
                                                                                                              ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? 1
                                                                                                                  : 0
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  null,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_email_enabled",
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_email_enabled",
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                item.content,
                                                                                                                "is_email_enabled",
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.edit(
                                                                                                              index
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "swicth-label",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for: `sendEmail${index}`,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_push_enabled,
                                                                                                          expression:
                                                                                                            "item.content.is_push_enabled",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "checkbox",
                                                                                                        id: `sendPushNotification${index}`,
                                                                                                        "true-value": 1,
                                                                                                        "false-value": 0,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        checked:
                                                                                                          Array.isArray(
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_push_enabled
                                                                                                          )
                                                                                                            ? _vm._i(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                                null
                                                                                                              ) >
                                                                                                              -1
                                                                                                            : _vm._q(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                                1
                                                                                                              ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? 1
                                                                                                                  : 0
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  null,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_push_enabled",
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_push_enabled",
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                item.content,
                                                                                                                "is_push_enabled",
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.edit(
                                                                                                              index
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "swicth-label",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for: `sendPushNotification${index}`,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                  item &&
                                                                  item.type ===
                                                                    "journey_item" &&
                                                                  (item.content
                                                                    .sub_type ===
                                                                    "LIVE_EVENT" ||
                                                                    item.content
                                                                      .sub_type ===
                                                                      "MEETING")
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "container-fluid",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-5 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item journey-preview-list__item--primary",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon__item",
                                                                                                domProps:
                                                                                                  {
                                                                                                    innerHTML:
                                                                                                      _vm._s(
                                                                                                        _vm.$helpers.iconType(
                                                                                                          item
                                                                                                            .content
                                                                                                            .sub_type
                                                                                                        )
                                                                                                      ),
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "title",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .content
                                                                                                  .name
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-4 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item",
                                                                                        class:
                                                                                          {
                                                                                            "is-editing":
                                                                                              _vm
                                                                                                .record
                                                                                                .id ===
                                                                                              item.id,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "date-picker",
                                                                                          {
                                                                                            ref: `datepicker${index}`,
                                                                                            refInFor: true,
                                                                                            staticClass:
                                                                                              "date",
                                                                                            attrs:
                                                                                              {
                                                                                                lang: _vm.userLang,
                                                                                                type: "datetime",
                                                                                                "disabled-date":
                                                                                                  _vm.notBeforeToday,
                                                                                                "disabled-time":
                                                                                                  _vm.notBeforeTime,
                                                                                                "value-type":
                                                                                                  "YYYY-MM-DD HH:mm:ss",
                                                                                                format:
                                                                                                  _vm.userLang ===
                                                                                                  "en"
                                                                                                    ? "MM.DD.YYYY / hh:mm A"
                                                                                                    : "DD.MM.YYYY / HH:mm",
                                                                                                "time-picker-options":
                                                                                                  {
                                                                                                    start:
                                                                                                      "08:00",
                                                                                                    step: "00:15",
                                                                                                    end: "23:45",
                                                                                                    format:
                                                                                                      _vm.userLang ===
                                                                                                      "en"
                                                                                                        ? "hh:mm A"
                                                                                                        : "HH:mm",
                                                                                                  },
                                                                                              },
                                                                                            on: {
                                                                                              "update:disabledDate":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.notBeforeToday =
                                                                                                    $event
                                                                                                },
                                                                                              "update:disabled-date":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.notBeforeToday =
                                                                                                    $event
                                                                                                },
                                                                                              "update:disabledTime":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.notBeforeTime =
                                                                                                    $event
                                                                                                },
                                                                                              "update:disabled-time":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.notBeforeTime =
                                                                                                    $event
                                                                                                },
                                                                                              change:
                                                                                                (
                                                                                                  date,
                                                                                                  type
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    type,
                                                                                                    date,
                                                                                                    "starting_date"
                                                                                                  )
                                                                                                },
                                                                                              input:
                                                                                                (
                                                                                                  date
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    "manuel",
                                                                                                    date,
                                                                                                    "starting_date"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  item
                                                                                                    .content
                                                                                                    .starting_date,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      item.content,
                                                                                                      "starting_date",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "item.content.starting_date",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-calendar",
                                                                                                  },
                                                                                                slot: "icon-calendar",
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-clear",
                                                                                                  },
                                                                                                slot: "icon-clear",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "button-edit",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.showDatePicker(
                                                                                                    `datepicker${index}`
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "svg",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    width:
                                                                                                      "12",
                                                                                                    height:
                                                                                                      "12",
                                                                                                    viewBox:
                                                                                                      "0 0 12 12",
                                                                                                    fill: "none",
                                                                                                    xmlns:
                                                                                                      "http://www.w3.org/2000/svg",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "mask",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id: "mask0",
                                                                                                        "mask-type":
                                                                                                          "alpha",
                                                                                                        maskUnits:
                                                                                                          "userSpaceOnUse",
                                                                                                        x: "0",
                                                                                                        y: "0",
                                                                                                        width:
                                                                                                          "12",
                                                                                                        height:
                                                                                                          "12",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M0 0H11.9556V11.9291H0V0Z",
                                                                                                            fill: "white",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "g",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        mask: "url(#mask0)",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z",
                                                                                                            fill: "#374163",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm.showPanel,
                                                                                          expression:
                                                                                            "showPanel",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "col-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex justify-content-center aling-items-center mx-4",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_email_enabled,
                                                                                                          expression:
                                                                                                            "item.content.is_email_enabled",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "checkbox",
                                                                                                        id: `sendEmail${index}`,
                                                                                                        "true-value": 1,
                                                                                                        "false-value": 0,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        checked:
                                                                                                          Array.isArray(
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_email_enabled
                                                                                                          )
                                                                                                            ? _vm._i(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                                null
                                                                                                              ) >
                                                                                                              -1
                                                                                                            : _vm._q(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                                1
                                                                                                              ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_email_enabled,
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? 1
                                                                                                                  : 0
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  null,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_email_enabled",
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_email_enabled",
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                item.content,
                                                                                                                "is_email_enabled",
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.edit(
                                                                                                              index
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "swicth-label",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for: `sendEmail${index}`,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_push_enabled,
                                                                                                          expression:
                                                                                                            "item.content.is_push_enabled",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "checkbox",
                                                                                                        id: `sendPushNotification${index}`,
                                                                                                        "true-value": 1,
                                                                                                        "false-value": 0,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        checked:
                                                                                                          Array.isArray(
                                                                                                            item
                                                                                                              .content
                                                                                                              .is_push_enabled
                                                                                                          )
                                                                                                            ? _vm._i(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                                null
                                                                                                              ) >
                                                                                                              -1
                                                                                                            : _vm._q(
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                                1
                                                                                                              ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                item
                                                                                                                  .content
                                                                                                                  .is_push_enabled,
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? 1
                                                                                                                  : 0
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  null,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_push_enabled",
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    item.content,
                                                                                                                    "is_push_enabled",
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                item.content,
                                                                                                                "is_push_enabled",
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.edit(
                                                                                                              index
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "swicth-label",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for: `sendPushNotification${index}`,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "show",
                                                                                      rawName:
                                                                                        "v-show",
                                                                                      value:
                                                                                        _vm.isValidated,
                                                                                      expression:
                                                                                        "isValidated",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12 d-flex help is-danger justify-content-end m-0",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.checkDateValidation(
                                                                                            item
                                                                                              .content
                                                                                              .starting_date
                                                                                          )
                                                                                            ? _vm.$t(
                                                                                                "not_before"
                                                                                              )
                                                                                            : ""
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                  item &&
                                                                  item.type ===
                                                                    "item_scheduled_notification" &&
                                                                  (item.content
                                                                    .sub_type !==
                                                                    "LIVE_EVENT" ||
                                                                    item.content
                                                                      .sub_type !==
                                                                      "MEETING")
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "container-fluid",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-5 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item journey-preview-list__item--primary",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon__item",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "icon-container",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        width: 22,
                                                                                                        height: 24,
                                                                                                        name: "notification",
                                                                                                        "view-box":
                                                                                                          "0 0 22 24",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "icon-notification"
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "title",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item
                                                                                                  ?.content
                                                                                                  ?.title
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class:
                                                                                      _vm.showPanel
                                                                                        ? "col-4 d-flex align-content-center"
                                                                                        : "col-6 d-flex align-content-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "journey-preview-list__item",
                                                                                        class:
                                                                                          {
                                                                                            "is-editing":
                                                                                              _vm
                                                                                                .record
                                                                                                .id ===
                                                                                              item.id,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "date-picker",
                                                                                          {
                                                                                            ref: `datepickernotif${index}`,
                                                                                            refInFor: true,
                                                                                            staticClass:
                                                                                              "date",
                                                                                            attrs:
                                                                                              {
                                                                                                lang: _vm.userLang,
                                                                                                type: "datetime",
                                                                                                "value-type":
                                                                                                  "YYYY-MM-DD HH:mm:ss",
                                                                                                format:
                                                                                                  _vm.userLang ===
                                                                                                  "en"
                                                                                                    ? "MM.DD.YYYY / hh:mm A"
                                                                                                    : "DD.MM.YYYY / HH:mm",
                                                                                                "time-picker-options":
                                                                                                  {
                                                                                                    start:
                                                                                                      "08:00",
                                                                                                    step: "00:15",
                                                                                                    end: "23:45",
                                                                                                    format:
                                                                                                      _vm.userLang ===
                                                                                                      "en"
                                                                                                        ? "hh:mm A"
                                                                                                        : "HH:mm",
                                                                                                  },
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                (
                                                                                                  date,
                                                                                                  type
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    type,
                                                                                                    date,
                                                                                                    "activation_date"
                                                                                                  )
                                                                                                },
                                                                                              input:
                                                                                                (
                                                                                                  date
                                                                                                ) => {
                                                                                                  _vm.edit(
                                                                                                    index,
                                                                                                    "manuel",
                                                                                                    date,
                                                                                                    "activation_date"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  item
                                                                                                    .content
                                                                                                    .activation_date,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      item.content,
                                                                                                      "activation_date",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "item.content.activation_date",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-calendar",
                                                                                                  },
                                                                                                slot: "icon-calendar",
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "icon-clear",
                                                                                                  },
                                                                                                slot: "icon-clear",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "button-edit",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.showDatePicker(
                                                                                                    `datepickernotif${index}`
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "svg",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    width:
                                                                                                      "12",
                                                                                                    height:
                                                                                                      "12",
                                                                                                    viewBox:
                                                                                                      "0 0 12 12",
                                                                                                    fill: "none",
                                                                                                    xmlns:
                                                                                                      "http://www.w3.org/2000/svg",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "mask",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id: "mask0",
                                                                                                        "mask-type":
                                                                                                          "alpha",
                                                                                                        maskUnits:
                                                                                                          "userSpaceOnUse",
                                                                                                        x: "0",
                                                                                                        y: "0",
                                                                                                        width:
                                                                                                          "12",
                                                                                                        height:
                                                                                                          "12",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M0 0H11.9556V11.9291H0V0Z",
                                                                                                            fill: "white",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "g",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        mask: "url(#mask0)",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "path",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "fill-rule":
                                                                                                              "evenodd",
                                                                                                            "clip-rule":
                                                                                                              "evenodd",
                                                                                                            d: "M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z",
                                                                                                            fill: "#374163",
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm.showPanel,
                                                                                          expression:
                                                                                            "showPanel",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "col-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex justify-content-center aling-items-center mx-4",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "swicth-checkbox px-2 mt-2",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.errorBag.length > 0 &&
                                      _vm.isValidated,
                                    expression:
                                      "errorBag.length > 0 && isValidated",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "alert alert--card alert--error",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                                    ]),
                                    _c("p", { staticClass: "help is-danger" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("fix_date")) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button button--primary",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.isPending ||
                                      _vm.canNotEdit ||
                                      _vm.isNotDirty,
                                  },
                                  on: { click: _vm.rescheduleJourney },
                                },
                                [
                                  !_vm.isPending
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("reschedule")) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isPending
                                    ? _c(
                                        "div",
                                        { staticClass: "lds-ellipsis" },
                                        [
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.duplicated_journey_id
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tabPane === "reminderSettingsTab",
                                  expression:
                                    "tabPane === 'reminderSettingsTab'",
                                },
                              ],
                              staticClass: "tab-pane",
                            },
                            [
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-auto" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.hasReminder,
                                            expression: "hasReminder",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "hasReminder",
                                          id: "hasReminder",
                                          checked: "",
                                          disabled: _vm.canNotEdit,
                                          "true-value": true,
                                          "false-value": false,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.hasReminder
                                          )
                                            ? _vm._i(_vm.hasReminder, null) > -1
                                            : _vm.hasReminder,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.hasReminder,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.hasReminder = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.hasReminder = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.hasReminder = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: { for: "hasReminder" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("hasReminder")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "collapse-transition",
                                    {
                                      attrs: {
                                        duration: 500,
                                        dimension: "height",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.hasReminder,
                                              expression: "hasReminder",
                                            },
                                          ],
                                          staticClass: "container mt-5",
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-3 form-label",
                                                    attrs: {
                                                      for: "employee_title",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "employee_title"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-9" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.employee_title,
                                                          expression:
                                                            "employee_title",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: _vm.hasReminder
                                                            ? "required|max:40"
                                                            : "",
                                                          expression:
                                                            "hasReminder ? 'required|max:40':''",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            "journey.employee_title"
                                                          ),
                                                      },
                                                      attrs: {
                                                        type: "text",
                                                        id: "employee_title",
                                                        "data-vv-name":
                                                          "employee_title",
                                                        "data-vv-scope":
                                                          "journey",
                                                        "data-vv-as":
                                                          _vm.$t(
                                                            "employee_title"
                                                          ),
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.employee_title,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.employee_title =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _vm.errors.has(
                                                      "journey.employee_title"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "journey.employee_title"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-3 form-label",
                                                    attrs: {
                                                      for: "employee_content",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "employee_content"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-9" },
                                                  [
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.employee_content,
                                                          expression:
                                                            "employee_content",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: _vm.hasReminder
                                                            ? "required|max:160"
                                                            : "",
                                                          expression:
                                                            "hasReminder ? 'required|max:160':''",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            "journey.employee_content"
                                                          ),
                                                      },
                                                      attrs: {
                                                        id: "employee_content",
                                                        "data-vv-name":
                                                          "employee_content",
                                                        "data-vv-scope":
                                                          "journey",
                                                        "data-vv-as":
                                                          _vm.$t(
                                                            "employee_content"
                                                          ),
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.employee_content,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.employee_content =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _vm.errors.has(
                                                      "journey.employee_content"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "journey.employee_content"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-3 form-label",
                                                    attrs: {
                                                      for: "weeks_after_last_content",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "weeks_after_last_content"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.weeks_after_last_content,
                                                            expression:
                                                              "weeks_after_last_content",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "selectbox-o",
                                                        attrs: {
                                                          id: "weeks_after_last_content",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.weeks_after_last_content =
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        [
                                                          1, 2, 3, 4, 5, 6, 7,
                                                          8, 9,
                                                        ],
                                                        function (n) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: n,
                                                              domProps: {
                                                                value: n,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(n) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "form-label mx-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("week"))
                                                        ),
                                                      ]
                                                    ),
                                                    _c("svg-icon", {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName:
                                                            "v-tooltip.top",
                                                          value: _vm.$t(
                                                            "weeks_after_last_content_info"
                                                          ),
                                                          expression:
                                                            "$t('weeks_after_last_content_info')",
                                                          modifiers: {
                                                            top: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "form-label",
                                                      attrs: {
                                                        type: "mdi",
                                                        path: _vm.iconInformationOutline,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-3 form-label",
                                                    attrs: { for: "week_span" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("week_span")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.week_span,
                                                            expression:
                                                              "week_span",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "selectbox-o",
                                                        attrs: {
                                                          id: "week_span",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.week_span =
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        [1, 2, 3, 4],
                                                        function (n) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: n,
                                                              domProps: {
                                                                value: n,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(n) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "form-label mx-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("week"))
                                                        ),
                                                      ]
                                                    ),
                                                    _c("svg-icon", {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName:
                                                            "v-tooltip.top",
                                                          value:
                                                            _vm.$t(
                                                              "week_span_info"
                                                            ),
                                                          expression:
                                                            "$t('week_span_info')",
                                                          modifiers: {
                                                            top: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "form-label",
                                                      attrs: {
                                                        type: "mdi",
                                                        path: _vm.iconInformationOutline,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-3 form-label",
                                                    attrs: {
                                                      for: "day_of_week",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("day_of_week")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.day_of_week,
                                                            expression:
                                                              "day_of_week",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "selectbox-o",
                                                        attrs: {
                                                          id: "day_of_week",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.day_of_week =
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        [1, 2, 3, 4, 5, 6, 7],
                                                        function (n) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: n,
                                                              domProps: {
                                                                value: n,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$helpers.getWeekDay(
                                                                      n
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button button--primary",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.canNotEdit,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabNavigation("mainTab")
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tabPane === "advancedSettingsTab",
                              expression: "tabPane === 'advancedSettingsTab'",
                            },
                          ],
                          staticClass: "tab-pane",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group form-group--cards" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 col-sm-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "uploadThumbnail" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("upload_catalog_cover"))
                                        ),
                                      ]
                                    ),
                                    _c("thumbnail-container", {
                                      staticClass: "card card--upload",
                                      class: {
                                        "is-danger":
                                          _vm.errors.has("journey.thumbnail"),
                                      },
                                      attrs: {
                                        "thumbnail-image":
                                          _vm.journeyThumbnailPreview,
                                        "vee-rule":
                                          "required|size:5000|mimes:image/jpeg,image/png",
                                        "vee-scoop-name": "journey",
                                        "vee-name": "thumbnail",
                                        "is-disable": _vm.canNotEdit,
                                      },
                                      on: { update: _vm.thumbnailAdded },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.errors.has(
                                                "journey.thumbnail"
                                              ),
                                            expression:
                                              "errors.has('journey.thumbnail')",
                                          },
                                        ],
                                        staticClass: "help is-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "journey.thumbnail"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.statusCatalog,
                                      expression: "statusCatalog",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "listOnTheCatalog",
                                    id: "listOnTheCatalog",
                                    disabled: _vm.canNotEdit,
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.statusCatalog)
                                      ? _vm._i(_vm.statusCatalog, null) > -1
                                      : _vm._q(_vm.statusCatalog, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.statusCatalog,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.statusCatalog = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.statusCatalog = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.statusCatalog = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: { for: "listOnTheCatalog" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("list_on_catalog")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm.isVibonsAdmin
                              ? _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_public,
                                            expression: "is_public",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "setPublic",
                                          id: "setPublic",
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(_vm.is_public)
                                            ? _vm._i(_vm.is_public, null) > -1
                                            : _vm._q(_vm.is_public, 1),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.is_public,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_public = $$a.concat([
                                                    $$v,
                                                  ]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_public = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.is_public = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: { for: "setPublic" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("is_public_journey")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.is_self_assign,
                                      expression: "is_self_assign",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "is_self_assign",
                                    id: "is_self_assign",
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.is_self_assign)
                                      ? _vm._i(_vm.is_self_assign, null) > -1
                                      : _vm._q(_vm.is_self_assign, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.is_self_assign,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_self_assign = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_self_assign = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_self_assign = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: { for: "is_self_assign" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("is_self_assign")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm.showHideFromLxp
                              ? _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.is_in_lxp_library,
                                            expression: "is_in_lxp_library",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideFromLxp",
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.is_in_lxp_library
                                          )
                                            ? _vm._i(
                                                _vm.is_in_lxp_library,
                                                null
                                              ) > -1
                                            : _vm._q(_vm.is_in_lxp_library, 1),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.is_in_lxp_library,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.is_in_lxp_library =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.is_in_lxp_library = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.is_in_lxp_library = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: { for: "hideFromLxp" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("is_in_lxp_library")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-6 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "swicth-checkbox mt-2" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.can_be_assigned_on_weekends,
                                          expression:
                                            "can_be_assigned_on_weekends",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "can_be_assigned_on_weekends",
                                        disabled:
                                          _vm.editMode && _vm.journeyHaveUsers,
                                        "true-value": 1,
                                        "false-value": 0,
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.can_be_assigned_on_weekends
                                        )
                                          ? _vm._i(
                                              _vm.can_be_assigned_on_weekends,
                                              null
                                            ) > -1
                                          : _vm._q(
                                              _vm.can_be_assigned_on_weekends,
                                              1
                                            ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.can_be_assigned_on_weekends,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 1 : 0
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.can_be_assigned_on_weekends =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.can_be_assigned_on_weekends =
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.can_be_assigned_on_weekends =
                                              $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "swicth-label",
                                        attrs: {
                                          for: "can_be_assigned_on_weekends",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "can_be_assigned_on_weekends"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t(
                                        "can_be_assigned_on_weekends_info"
                                      ),
                                      expression:
                                        "$t('can_be_assigned_on_weekends_info')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  staticClass: "form-label mx-4",
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.is_self_paced,
                                      expression: "is_self_paced",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "selfPaced",
                                    id: "selfPaced",
                                    checked: "",
                                    disabled: _vm.canNotEdit,
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.is_self_paced)
                                      ? _vm._i(_vm.is_self_paced, null) > -1
                                      : _vm._q(_vm.is_self_paced, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.is_self_paced,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_self_paced = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_self_paced = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_self_paced = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: { for: "selfPaced" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("self_paced_option")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.is_mandatory,
                                      expression: "is_mandatory",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "isMandatory",
                                    id: "isMandatory",
                                    checked: "",
                                    disabled: _vm.canNotEdit,
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.is_mandatory)
                                      ? _vm._i(_vm.is_mandatory, null) > -1
                                      : _vm._q(_vm.is_mandatory, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.is_mandatory,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_mandatory = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_mandatory = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_mandatory = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: { for: "isMandatory" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("is_mandatory")) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.use_employment_start_date,
                                      expression: "use_employment_start_date",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "use_employment_start_date",
                                    id: "use_employment_start_date",
                                    disabled: _vm.canNotEdit,
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.use_employment_start_date
                                    )
                                      ? _vm._i(
                                          _vm.use_employment_start_date,
                                          null
                                        ) > -1
                                      : _vm._q(
                                          _vm.use_employment_start_date,
                                          1
                                        ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.use_employment_start_date,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.use_employment_start_date =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.use_employment_start_date = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.use_employment_start_date = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: { for: "use_employment_start_date" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("use_employment_start_date")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.canNotEdit,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tabNavigation("mainTab")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tabPane === "assignProfileTab",
                              expression: "tabPane === 'assignProfileTab'",
                            },
                          ],
                          staticClass: "tab-pane",
                        },
                        [
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "swicth-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.is_automatic_assignment_enabled,
                                      expression:
                                        "is_automatic_assignment_enabled",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "is_automatic_assignment_enabled",
                                    id: "is_automatic_assignment_enabled",
                                    disabled: _vm.canNotEdit,
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.is_automatic_assignment_enabled
                                    )
                                      ? _vm._i(
                                          _vm.is_automatic_assignment_enabled,
                                          null
                                        ) > -1
                                      : _vm._q(
                                          _vm.is_automatic_assignment_enabled,
                                          1
                                        ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.is_automatic_assignment_enabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_automatic_assignment_enabled =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_automatic_assignment_enabled =
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_automatic_assignment_enabled =
                                          $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "swicth-label",
                                    attrs: {
                                      for: "is_automatic_assignment_enabled",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "profile_assignment_is_enabled"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "collapse-transition",
                            { attrs: { duration: 500, dimension: "height" } },
                            [
                              _vm.is_automatic_assignment_enabled
                                ? _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _vm._l(
                                        _vm.rules,
                                        function (the_rule, inx) {
                                          return _c(
                                            "div",
                                            {
                                              key: inx,
                                              staticClass:
                                                "form-group row m-0 p-0 mb-4",
                                              staticStyle: {
                                                position: "relative",
                                                "margin-bottom": "10px",
                                                "padding-bottom": "30px",
                                                "border-bottom":
                                                  "0.5px solid #eee",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-6",
                                                  staticStyle: {
                                                    "min-height": "200px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "form-label",
                                                      attrs: {
                                                        for: "selectuserData",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user_data_selection"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("multi-select", {
                                                    staticClass:
                                                      "selectbox selectbox--secondary flatmultiselect",
                                                    attrs: {
                                                      id: "selectuserData",
                                                      name: "selectuserData",
                                                      "track-by": "value",
                                                      label: "name",
                                                      "select-label": "",
                                                      placeholder:
                                                        _vm.$t(
                                                          "select_user_data"
                                                        ),
                                                      "allow-empty": true,
                                                      multiple: true,
                                                      searchable: false,
                                                      "close-on-select": false,
                                                      "internal-search": false,
                                                      "show-labels": true,
                                                      taggable: true,
                                                      "clear-on-select": false,
                                                      options: _vm.userData,
                                                    },
                                                    on: {
                                                      remove: (
                                                        removedOption,
                                                        id
                                                      ) =>
                                                        _vm.removeUserData(
                                                          removedOption,
                                                          id,
                                                          inx
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedUserData[
                                                          inx
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.selectedUserData,
                                                          inx,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedUserData[inx]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _vm._l(
                                                    _vm.selectedUserData[inx],
                                                    function (infotype, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "form-group row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12 d-flex flex-column justify-content-center align-items-start",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-label text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          infotype.value ===
                                                                            "title"
                                                                            ? "the_title"
                                                                            : infotype.value
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "multi-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "selectbox selectbox--secondary",
                                                                  class: {
                                                                    "is-danger":
                                                                      _vm.errors.has(
                                                                        `${infotype.value}_${inx}_${index}`
                                                                      ),
                                                                  },
                                                                  attrs: {
                                                                    name: `${infotype.value}_${inx}_${index}`,
                                                                    "allow-empty": true,
                                                                    multiple: true,
                                                                    placeholder:
                                                                      _vm.$t(
                                                                        "select_placeholder_taggable"
                                                                      ),
                                                                    "tag-placeholder":
                                                                      _vm.$t(
                                                                        "tag_placeholder"
                                                                      ),
                                                                    "deselect-label":
                                                                      _vm.$t(
                                                                        "deselect_placeholder"
                                                                      ),
                                                                    "select-label":
                                                                      _vm.$t(
                                                                        "enter_select_placeholder"
                                                                      ),
                                                                    "selected-label":
                                                                      _vm.$t(
                                                                        "selected_label"
                                                                      ),
                                                                    "show-labels": true,
                                                                    taggable: true,
                                                                    "track-by":
                                                                      "value",
                                                                    label:
                                                                      "value",
                                                                    "clear-on-select": true,
                                                                    options:
                                                                      _vm.rules[
                                                                        inx
                                                                      ]
                                                                        .optiontypes[
                                                                        infotype
                                                                          .value
                                                                      ],
                                                                    "data-vv-scope":
                                                                      "journey",
                                                                    "data-vv-name": `${infotype.value}_${inx}_${index}`,
                                                                    "data-vv-as":
                                                                      _vm.$t(
                                                                        infotype.value ===
                                                                          "title"
                                                                          ? "the_title"
                                                                          : infotype.value
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    tag: (rr) =>
                                                                      _vm.addTag(
                                                                        rr,
                                                                        infotype,
                                                                        inx,
                                                                        index
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.rules[
                                                                        inx
                                                                      ]
                                                                        .conditions[
                                                                        infotype
                                                                          .value
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .rules[
                                                                            inx
                                                                          ]
                                                                            .conditions,
                                                                          infotype.value,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "rules[inx].conditions[infotype.value]",
                                                                  },
                                                                }
                                                              ),
                                                              _vm.errors.has(
                                                                `journey.${infotype.value}_${inx}_${index}`
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "help is-danger",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.errors.first(
                                                                            `journey.${infotype.value}_${inx}_${index}`
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center mt-4",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.rules[inx]
                                                                .day_offset,
                                                            expression:
                                                              "rules[inx].day_offset",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          width: "75px",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          id: "day_offset",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.rules[inx]
                                                              .day_offset,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.rules[inx],
                                                              "day_offset",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "day_offset"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button button--icon",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeRule(inx)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip.top",
                                                        value: _vm.$t(
                                                          "delete_identifier_group"
                                                        ),
                                                        expression:
                                                          "$t('delete_identifier_group')",
                                                        modifiers: {
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-label mx-4",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      top: "0",
                                                      right: "-10px",
                                                    },
                                                    attrs: {
                                                      type: "mdi",
                                                      path: _vm.iconTrash,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row align-content-center justify-content-center",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--text d-flex flex-row align-items-center",
                                              attrs: { type: "button" },
                                              on: { click: _vm.addToRules },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "form-label",
                                                attrs: {
                                                  type: "mdi",
                                                  path: _vm.iconPlus,
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "add_identifier_group"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.canNotEdit,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tabNavigation("mainTab")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }