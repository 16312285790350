<template>
  <div>
    <div
      :class="{ [drop]: true }"
      style="position: relative"
    >
      <span
        class="form-control color-box dropdown"
        @mouseover="dropdownAction(`colorpalette`)"
        @mouseleave="hideDropdownAction"
        title="Pick a color"
      >
        <span
          class="form-control color-box-inside"
          :style="{ 'background-color': color }"
        >&nbsp;</span>
        &nbsp;<i
class="fa fa-caret-down text-muted"
id="color-picker-arrow"
/>
      </span>
      <ul
        class="dropdown__toggle color-palette"
        @mouseover="dropdownAction(`colorpalette`)"
        @mouseleave="hideDropdownAction"
        ref="palette"
        id="colorMenu"
        v-if="dropdownIndex === 'colorpalette'"
        v-on-clickaway="hideDropdownAction"
      >
        <li class="color-holder">
          <!--eslint-disable-next-line vue/require-v-for-key -->
          <div
            class="color-box-sm"
            :style="{ 'background-color': c }"
            v-for="c in colors"
            @click="color = c"
          >
            <i
              class="fa fa-check"
              style="position: absolute"
              v-show="c === color"
            />
          </div>

          <div class="mr-1">
            <input
              type="text"
              class="form-control color-text"
              v-model.trim="color"
              maxlength="7"
              placeholder="Color value"
              @keyup.13.27="$refs.palette.classList.remove('show')"
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
  props: {
    value: { type: String, default: '' },
    colors: {
      type: Array,
      default: () => [
        '#000000',
        '#993300',
        '#333300',
        '#000080',
        '#333399',
        '#333333',
        '#800000',
        '#FF6600',
        '#808000',
        '#008000',
        '#008080',
        '#0000FF',
        '#666699',
        '#808080',
        '#FF0000',
        '#FF9900',
        '#99CC00',
        '#339966',
        '#33CCCC',
        '#3366FF',
        '#800080',
        '#999999',
        '#FF00FF',
        '#FFCC00',
        '#FFFF00',
        '#00FF00',
        '#00FFFF',
        '#00CCFF',
        '#993366',
        '#C0C0C0',
        '#FFCC99',
        '#FFFF99',
        '#CCFFFF',
        '#99CCFF',
        '#FFFFFF',
      ],
    },
    drop: { type: String, default: 'dropdown' },
  },
  data() {
    return {
      color: this.value || '',
      dropdownIndex: null,
    };
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  watch: {
    value(v) {
      this.color = v;
    },
    color(v) {
      if (!/^#/.test(v)) {
        // eslint-disable-next-line no-return-assign
        this.$nextTick(() => (this.color = this.color ? `#${this.color}` : ''));
      }

      this.$emit('input', v);
    },
  },
  methods: {
    hideDropdownAction() {
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },
    dropdownAction(value) {
      clearTimeout(this.timer);
      this.dropdownIndex = value;
      setTimeout(() => {
        const elem = this.$refs.palette;
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottomjourney');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },
  },
};
</script>

<style scoped>
.color-box {
  width: 34px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 0;
}

.color-box-inside {
  left: 1px;
  top: 1px;
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 0;
}

.color-box-sm {
  width: 18px;
  height: 18px;
  border: 1px solid gray;
  display: inline-block;
  margin: 2px;
  cursor: pointer;
  position: relative;
}

.color-text {
  margin-left: 2px;
  width: 97%;
}

.color-holder {
  padding-left: 2px;
}

#color-picker-arrow {
  position: absolute;
  bottom: 0;
  right: 2px;
  color: rgba(0, 0, 0, 0.5);
}

.color-palette {
  max-width: 160px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.5rem 0;
}
.dropdown__toggle {
        display: block;
        cursor: pointer;
        position: absolute;
        top:0;
        left:0;
        z-index: 10;
      }
</style>
