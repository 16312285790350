var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: { [_vm.drop]: true }, staticStyle: { position: "relative" } },
      [
        _c(
          "span",
          {
            staticClass: "form-control color-box dropdown",
            attrs: { title: "Pick a color" },
            on: {
              mouseover: function ($event) {
                return _vm.dropdownAction(`colorpalette`)
              },
              mouseleave: _vm.hideDropdownAction,
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "form-control color-box-inside",
                style: { "background-color": _vm.color },
              },
              [_vm._v(" ")]
            ),
            _vm._v("  "),
            _c("i", {
              staticClass: "fa fa-caret-down text-muted",
              attrs: { id: "color-picker-arrow" },
            }),
          ]
        ),
        _vm.dropdownIndex === "colorpalette"
          ? _c(
              "ul",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideDropdownAction,
                    expression: "hideDropdownAction",
                  },
                ],
                ref: "palette",
                staticClass: "dropdown__toggle color-palette",
                attrs: { id: "colorMenu" },
                on: {
                  mouseover: function ($event) {
                    return _vm.dropdownAction(`colorpalette`)
                  },
                  mouseleave: _vm.hideDropdownAction,
                },
              },
              [
                _c(
                  "li",
                  { staticClass: "color-holder" },
                  [
                    _vm._l(_vm.colors, function (c) {
                      return _c(
                        "div",
                        {
                          staticClass: "color-box-sm",
                          style: { "background-color": c },
                          on: {
                            click: function ($event) {
                              _vm.color = c
                            },
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: c === _vm.color,
                                expression: "c === color",
                              },
                            ],
                            staticClass: "fa fa-check",
                            staticStyle: { position: "absolute" },
                          }),
                        ]
                      )
                    }),
                    _c("div", { staticClass: "mr-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.color,
                            expression: "color",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control color-text",
                        attrs: {
                          type: "text",
                          maxlength: "7",
                          placeholder: "Color value",
                        },
                        domProps: { value: _vm.color },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              $event.keyCode !== 13 &&
                              $event.keyCode !== 27
                            )
                              return null
                            return _vm.$refs.palette.classList.remove("show")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.color = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }