<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('add_to_journey') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div class="modal-body">
            <div
              v-if="pageIsLoading"
              class="page-loader"
            >
              <div class="page-is-loading" />
            </div>
            <div class="form-group">
              <label
                for="journey"
                class="form-label"
              >{{ $t('journeys') }}</label>
              <vue-tags-input
                :class="{ 'is-danger': errors.has('add.journey') }"
                v-model="journey"
                :tags="journeys"
                :max-tags="1"
                @tags-changed="inputUpdateJourneys"
                :autocomplete-items="journeysToAdd"
                :add-only-from-autocomplete="true"
                :delete-on-backspace="false"
                :placeholder="$t('addjourney_placeholder')"
              />
              <input
                type="hidden"
                data-vv-scope="add"
                data-vv-name="journey"
                :data-vv-as="$t('journeys')"
                v-model="journeysSelected"
                v-validate="'required'"
              >
              <span
                v-if="errors.has('add.journey')"
                class="help is-danger"
              >{{ errors.first('add.journey') }}</span>
            </div>
            <div
              v-show="errors.items.length > 0"
              class="form-group"
            >
              <div class="alert alert--card alert--error">
                <p>{{ $t('error_msg_title') }}</p>
                <ul>
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    {{ error.msg }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="addContentToJourney"
                :disabled="isPending"
              >
                <span
                  v-if="!isPending"
                >
                  {{ $t('add') }}
                </span>
                <div
                  class="lds-ellipsis"
                  v-if="isPending"
                >
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  API_JOURNEY_CATALOG,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import API from '@/services/';
import vueTagsInput from '@johmun/vue-tags-input';
import moment from 'moment';

export default {
  name: 'ModalAddContentToJourney',
  inject: ['$validator'],
  data() {
    return {
      journeysToAdd: [],
      journey: '',
      journeys: [],
      debounce: null,
      selectedJourneyId: null,
      journeysSelected: null,
      formSending: false,
      pageIsLoading: false,
      formIsCompleted: true,
      isPending: false,
    };
  },

  watch: {
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.formIsCompleted = false;
        } else {
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
    journey: 'getJourneys',
    journeys() {
      if (this.journeys && this.journeys.length > 0) {
        this.journeysSelected = this.journeys[0].text;
      } else {
        this.journeysSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('add.journey', this.journeysSelected);
      });
    },
  },

  components: {
    vueTagsInput,
  },

  computed: {
    customerId() {
      return this.$store.state.modal.customerIdToEdit;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },

      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },

    contentDetail: {
      get() {
        return this.$store.state.modal.contentDetail;
      },
      set(newValue) {
        this.$store.state.modal.contentDetail = newValue;
      },
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    dateFormat() {
      if (this.userLang) {
        if (this.userLang === 'en') {
          return 'MM.DD.YYYY hh:mm A';
        }
        if (this.userLang === 'tr') {
          return 'DD.MM.YYYY HH:mm';
        }
      }
      return API_SERVER_TIME_FORMAT;
    },
  },

  methods: {
    inputUpdateJourneys(newTags) {
      this.journeys = newTags;
      this.selectedJourneyId = this.journeys[0].id;
    },

    getJourneys() {
      this.journeysToAdd = [];

      const postData = { params: {} };
      postData.params.per_page = 1000;
      if (this.journey) {
        postData.params.search = this.searchFor;
      }
      if (!this.isVibonsAdmin) {
        postData.params.customer_id = this.customerId;
      }
      if (this.journey.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_JOURNEY_CATALOG, postData)
          .then((response) => {
            this.journeysToAdd = response.data.data.filter(i => i.user_count === 0).map(a => (
              {
                text: `${a.name} - [${moment(a.activation_date, API_SERVER_TIME_FORMAT).format(this.dateFormat)}]`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    setSpinner(value) {
      this.pageIsLoading = value;
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    addContentToJourney() {
      this.$validator.validate('add.journey').then((result) => {
        if (result) {
          this.isPending = true;
          const item = this.contentDetail;
          if (this.selectedJourneyId) {
            item.journeyId = this.selectedJourneyId;
          }
          item.canIRefresh = false;
          item.is_email_enabled = 1;
          item.is_notifications_enabled = 1;
          item.is_info_labels_hidden = 0;
          const self = this;
          const successMsg = this.$t('added_success');

          this.$store.dispatch('modal/ADD_CONTENT_ITEM_TO_JOURNEY', item).then(() => {
            if (!this.formError) {
              // no need to listen and refresh journeyitems page since loadind from content page
              self.$snotify.success(successMsg);
              self.closeModal();
            } else {
              self.formError = false;
              self.isPending = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.page-loader {
    z-index: 100;
    position: relative;
    top: 150px;
}

.page-is-loading {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: calc(50% - 32px);
}

.page-is-loading:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #666;
  border-color: #666 transparent #666 transparent;
  animation: page-is-loading 1.2s linear infinite;
}

@keyframes page-is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
</style>
