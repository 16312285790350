<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        :filter-on="the_filter.only_active_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_users"
                >
                {{ $t('only_active_users') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>
    <nav class="navigation navigation--ternary">
      <div class="container">
        <ul
          class="navigation__list nav"
          id="myRoles"
          role="tablist"
        >
          <template v-for="r in roleTypes">
            <li
              class="navigation__item"
              :key="`${r.value}-${r.name}`"
            >
              <button
                class="navigation__link"
                :class="{ 'active': role === Number(r.value)}"
                @click="role=r.value"
              >
                {{ r.name }}
              </button>
            </li>
          </template>
        </ul>
      </div>
    </nav>
    <div class="container">
      <div class="mb-4 navigation text-capitalize d-flex items-center">
        <router-link
          :to="{
            name: 'ReportJourneysV2',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> {{ journeyName }}
      </div>
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="type"
            slot-scope="props"
          >
            <div class="d-flex justify-content-center">
              <span>
                {{ $helpers.getSubTypeNameFromId(props.rowData.sub_type_id
                                                 , userLang) }}
              </span>
            </div>
          </template>

          <template
            slot="actions"
            slot-scope="props"
          >
            <div
              class="dropdown"
              @mouseover="dropdownToggle(`three${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </span>

              <div
                class="dropdown__menu"
                :ref="`three${props.rowIndex}`"
                v-if="dropdownIndex === 'three' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <span
                  class="dropdown__button button"
                  v-if="!!props.rowData.is_nps"
                  @click="onAction('nps-menu', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getImgUrl()"
                  />
                  {{ $t('show_nps_score') }}
                </span>
                <span
                  class="dropdown__button button"
                  v-if="props.rowData.sub_type_id === 11"
                  @click="onAction('survey-stats-menu', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getImgUrl()"
                  />
                  {{ $t('survey_stats') }}
                </span>
                <span
                  class="dropdown__button button"
                  v-if="props.rowData.sub_type_id === 11"
                  @click="onAction('survey-report', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getExcelImgUrl()"
                  />
                  {{ $t('survey_report') }}
                </span>
                <span
                  class="dropdown__button button"
                  v-if="props.rowData.sub_type_id === 10"
                  @click="onAction('quiz-report', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getExcelImgUrl()"
                  />
                  {{ $t('quiz_report') }}
                </span>
                <span
                  class="dropdown__button button"
                  v-if="props.rowData.sub_type_id === 10"
                  @click="onAction('quiz-detail-report', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getExcelImgUrl()"
                  />
                  {{ $t('quiz_detail_report_') }}
                </span>
                <span
                  class="dropdown__button button"
                  v-if="props.rowData.sub_type_id === 12"
                  @click="onAction('checklist-report', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getExcelImgUrl()"
                  />
                  {{ $t('checklist_report') }}
                </span>
              </div>
            </div>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconContainer from '@/components/elements/Icon.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import {
  API_REPORT_CHECKLIST_DOWNLOAD_V2,
  API_REPORT_JOURNEY_V2__CONTENTS,
  API_REPORT_QUIZ_DETAIL_DOWNLOAD_V2,
  API_REPORT_QUIZ_DOWNLOAD_V2,
  API_REPORT_SURVEY_DOWNLOAD_V2,
} from '@/helpers/config';
import myTableMixin from '@/helpers/myTableMixin.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import { directive as onClickaway } from 'vue-clickaway';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapGetters, mapState } from 'vuex';

import {
  mdiCardBulletedOutline,
  mdiFileExcelOutline,
} from '@mdi/js';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneyV2Contents',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        journey2_id: this.$route.params.journeyId,
        role: 3,
      },
      the_filter: {
        only_active_users: false,
      },
      journeyName: this.$route.query.journey,
      role: 3,
      roleTypes: [
        { name: 'USER', value: 3 },
        { name: 'HRBP', value: 0 },
        { name: 'BUDDY', value: 1 },
        { name: 'MANAGER', value: 2 },
        { name: 'OTHER', value: 4 },
      ],
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-threedots': IconThreedots,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Dropdown,
    SvgIcon,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneyV2Contents') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_users) {
          this.$set(this.moreParams, 'only_active_users', 1);
        } else {
          this.$delete(this.moreParams, 'only_active_users');
        }
      },
      immediate: true,
      deep: true,
    },
    role: {
      handler(role) {
        this.$set(this.moreParams, 'role', Number(role));
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    ...mapGetters({
      userLang: 'auth/userLang',
    }),
    apiUrl() {
      if (API_REPORT_JOURNEY_V2__CONTENTS) {
        return `${API_REPORT_JOURNEY_V2__CONTENTS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: `/dashboard/journeys_v2/users/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
        {
          text: this.$t('contents_subtitle'),
          url: `/dashboard/journeys_v2/contents/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
      ];
    },

    fields() {
      return [
        {
          name: 'name',
          sortField: 'name',
          title: this.$t('name'),
          width: '30%',
        },
        {
          name: 'type',
          title: this.$t('type'),
          sortField: 'sub_type',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'assigned_user_count',
          title: this.$t('assigned_user'),
          sortField: 'assigned_user_count',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'opened_user_count',
          title: this.$t('opened_user_count'),
          sortField: 'opened_user_count',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_user_count',
          title: this.$t('completed_user_count'),
          sortField: 'completed_user_count',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },
  methods: {
    getImgUrl() {
      return mdiCardBulletedOutline;
    },
    getExcelImgUrl() {
      return mdiFileExcelOutline;
    },
    makeQueryParams(sortOrder, currentPage, perPage) {
      // console.log('JSON:', JSON.stringify(sortOrder, null, 2));
      if (sortOrder.length > 0) {
        let sortString = sortOrder[0].sortField;
        if (sortString.endsWith('-slot')) {
          sortString = sortString.replace('-slot', '');
        }
        return {
          sort: sortString,
          order: sortOrder[0].direction,
          page: currentPage,
          per_page: perPage,
        };
      }
      return {
        sort: 'activation',
        order: 'asc',
        page: currentPage ?? 1,
        per_page: this.perPage,
      };
    },
    onAction(action, data) {
      // eslint-disable-next-line camelcase
      const { journey2_content_id } = data;
      console.log('User action to do', data);
      if (action === 'nps-menu') {
        // eslint-disable-next-line camelcase
        if (journey2_content_id) {
          console.log('User action to do journey2_content_id:', journey2_content_id); // TODO: complete dropdown action menu
          const obj = {
            modalType: 'modalNpsScore',
            modalData: {
              journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'survey-report') {
        if (data.journey2_content_id) {
          const obj = {
            modalType: 'modalReportDownloadV2',
            reportDownloadUrl: API_REPORT_SURVEY_DOWNLOAD_V2,
            reportModalTitle: this.$t('download_survey_report', { name: data.name }),
            reportDownloadRequest: 'post',
            dateOnly: true,
            reportDownloadHasStartDate: true,
            reportDownloadHasEndDate: true,
            reportDownloadPayload: {
              journey2_content_id: data.journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'survey-stats-menu') {
        // eslint-disable-next-line camelcase
        if (journey2_content_id) {
          const obj = {
            modalType: 'modalSurveyStats',
            modalData: {
              journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'checklist-report') {
        if (data.journey2_content_id) {
          const obj = {
            modalType: 'modalReportDownloadV2',
            reportDownloadUrl: API_REPORT_CHECKLIST_DOWNLOAD_V2,
            reportModalTitle: this.$t('download_checklist_report', { name: data.name }),
            reportDownloadRequest: 'post',
            dateOnly: true,
            reportDownloadHasStartDate: true,
            reportDownloadHasEndDate: true,
            reportDownloadPayload: {
              journey2_content_id: data.journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'quiz-report') {
        if (data.journey2_content_id) {
          const obj = {
            modalType: 'modalReportDownloadV2',
            reportDownloadUrl: API_REPORT_QUIZ_DOWNLOAD_V2,
            reportModalTitle: this.$t('download_quiz_report', { name: data.name }),
            reportDownloadRequest: 'post',
            dateOnly: true,
            reportDownloadHasStartDate: true,
            reportDownloadHasEndDate: true,
            reportDownloadPayload: {
              journey2_content_id: data.journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'quiz-detail-report') {
        if (data.journey2_content_id) {
          const obj = {
            modalType: 'modalReportDownloadV2',
            reportDownloadUrl: API_REPORT_QUIZ_DETAIL_DOWNLOAD_V2,
            reportModalTitle: this.$t('download_quiz_detail_report', { name: data.name }),
            reportDownloadRequest: 'post',
            dateOnly: true,
            reportDownloadHasStartDate: true,
            reportDownloadHasEndDate: true,
            reportDownloadPayload: {
              journey2_content_id: data.journey2_content_id,
            },
          };
          this.$helpers.toggleModal(obj);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .filter-bp__btn {
  border: none;
  padding: 0;
  cursor: pointer;
  }
::v-deep .swicth-checkbox {
  min-width: 200px;
}
::v-deep .swicth-checkbox label {
    color: #727d92;
}
@media (min-width: 1200px) {
  ::v-deep .container {
    max-width: 90%;
    min-width: 1024px;
  }
}
</style>
