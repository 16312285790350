<template>
  <div class="page">
    <transition
      name="fade"
      mode="out-in"
    >
      <modal-groupaddjourney
        v-if="modal.modalVisible && modal.modalType === 'modalGroupAddJourney'"
      />
      <modal-journey-remove
        v-if="modal.modalVisible && modal.modalType === 'modalJourneyRemoveGroup'"
      />
      <modal-group-add
        v-if="modal.modalVisible && modal.modalType === 'modalAddGroup'"
      />
      <modal-groupadduser v-if="modal.modalVisible && modal.modalType === 'modalGroupAddUser'" />
    </transition>
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
      add-group
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableLoaded"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <span>
              {{ props.rowData.name }}
            </span>
          </template>
          <template
            slot="user_count"
            slot-scope="props"
          >
            <span>
              <router-link
                :to="{
                  name: 'GroupUsers',
                  params: {
                    groupId: props.rowData.id,
                    title: props.rowData.name
                  }}"
              >
                {{ props.rowData.user_count }}
              </router-link>
            </span>
          </template>
          <template
            slot="assigned_journeys"
            slot-scope="props"
          >
            {{ assignedjourneys[props.rowData.id] }}
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <div
              class="dropdown mr-2"
              @mouseover="dropdownAction(`plus${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  width="18"
                  height="18"
                  view-box="0 0 24 24"
                  name="cplus"
                  color="#727d92"
                >
                  <icon-circleplus />
                </icon-container>
              </span>

              <div
                class="dropdown__menu"
                :ref="`plus${props.rowIndex}`"
                v-if="dropdownIndex === 'plus' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <!-- <span
                  class="dropdown__button button"
                  @click="onAction('start-journey', props.rowData)"
                >
                  <icon-container
                    :width="14"
                    :height="15"
                    view-box="0 0 448 512"
                    name="play"
                    color="#727d92"
                  >
                    <icon-play />
                  </icon-container>
                  {{ $t('start_journey') }}
                </span> -->
                <span
                  class="dropdown__button button"
                  @click="onAction('add-user', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    name="user"
                    color="#727d92"
                  >
                    <icon-user />
                  </icon-container>
                  {{ $t('add_user') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('add-journey', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    name="journey"
                    color="#727d92"
                  >
                    <icon-journey />
                  </icon-container>
                  {{ $t('add_to_existent_journey') }}
                </span>
              </div>
            </div>
            <div
              class="dropdown"
              @mouseover="dropdownAction(`three${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </span>
              <div
                class="dropdown__menu"
                :ref="`three${props.rowIndex}`"
                v-if="dropdownIndex === 'three' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <span
                  class="dropdown__button button"
                  @click="onAction('remove-journey', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getImgUrl('mdiYoutubeTv')"
                  />
                  {{ $t('remove_from_journey') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('notification', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    view-box="0 0 24 24"
                    name="edit"
                    color="#727d92"
                  >
                    <icon-bell />
                  </icon-container>
                  {{ $t('notification_button') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('download-journey-report', props.rowData)"
                >
                  <icon-container
                    width="13"
                    height="14"
                    name="Download Journey Report"
                    color="#727d92"
                  >
                    <icon-download />
                  </icon-container>
                  {{ $t('download_journey_report') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('delete-item', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    view-box="0 0 24 24"
                    name="trash"
                    color="#727d92"
                  >
                    <icon-trash />
                  </icon-container>
                  {{ $t('delete') }}
                </span>
              </div>
            </div>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconContainer from '@/components/elements/Icon.vue';
import IconBell from '@/components/icons/Bell.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconJourney from '@/components/icons/Journey.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconUser from '@/components/icons/User.vue';
import ModalGroupAddJourney from '@/container/modal/ModalGroupAddJourney.vue';
import ModalGroupAddUser from '@/container/modal/ModalGroupAddUser.vue';
import ModalJourneyRemoveGroup from '@/container/modal/ModalJourneyRemoveGroup.vue';
import ModalGroupAddTemplate from '@/container/modal/user/GroupAdd.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import { directive as onClickaway } from 'vue-clickaway';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapState } from 'vuex';
// import IconPlay from '@/components/icons/Play.vue';
import AppHeader from '@/components/AppHeader.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import {
  API_USER_GROUP,
  API_USER_GROUP_DATATABLES,
  API_USER_GROUP_JOURNEY_REPORT,
  API_USER_GROUP_JOURNEYS,
} from '@/helpers/config';
import API from '@/services/';
import SvgIcon from '@jamescoyle/vue-icon';
import {
  mdiAccountMultipleMinusOutline,
} from '@mdi/js';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'Groups',
  mixins: [myTableMixin],
  data() {
    return {
      assignedjourneys: {},
      tableIsLoading: false,
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
      },
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-edit': IconEdit,
    'icon-bell': IconBell,
    'icon-trash': IconTrash,
    'icon-user': IconUser,
    'icon-journey': IconJourney,
    // 'icon-play': IconPlay,
    'icon-circleplus': IconCirclePlus,
    'icon-download': IconDownload,
    'icon-threedots': IconThreedots,
    'app-header': AppHeader,
    'modal-group-add': ModalGroupAddTemplate,
    'modal-groupaddjourney': ModalGroupAddJourney,
    'modal-groupadduser': ModalGroupAddUser,
    'modal-journey-remove': ModalJourneyRemoveGroup,
    'vue-table': Vuetable,
    SvgIcon,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Groups') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 1000);
      }
    },

  },

  computed: {
    ...mapState([
      'modal',
    ]),
    apiUrl() {
      if (API_USER_GROUP_DATATABLES) {
        return `${API_USER_GROUP_DATATABLES}`;
      }
      return null;
    },
    fields() {
      return [
        {
          name: 'name',
          title: this.$t('groups_name'),
          sortField: 'name',
          width: '25%',
        },
        {
          name: 'user_count',
          title: this.$t('num_of_users'),
          width: '11%',
          dataClass: 'text-center user-count',
        },
        {
          name: 'assigned_journeys',
          title: this.$t('assigned_journeys'),
          dataClass: 'assigned-journeys',
          width: '54%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
        },
      ];
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
        // {
        //   text: this.$t('managers'),
        //   url: '/managers',
        // },
        // {
        //   text: this.$t('buddies'),
        //   url: '/buddies',
        // },
      ];
    },
  },

  methods: {
    getImgUrl() {
      return mdiAccountMultipleMinusOutline;
    },
    getAssignedJourneys(groupid) {
      if (this.assignedjourneys[groupid]) {
        return this.assignedjourneys[groupid];
      }
      API.get(`${API_USER_GROUP_JOURNEYS}/${groupid}`)
        .then((response) => {
          const { data } = response.data;
          const name = [];
          if (data) {
            data.forEach(item => name.push(item.name));
          }
          if (name && name.length > 0) {
            this.$set(this.assignedjourneys, groupid, name.join(', '));
          } else {
            this.$set(this.assignedjourneys, groupid, this.$t('no_journey_assigned'));
          }
        })
        .catch(error => console.log(error));
      return false;
    },
    tableLoaded() {
      this.tableIsLoading = false;
      console.log(`table loaded: ${JSON.stringify(this.$refs.myvuetable.tableData)}`);
      this.$refs.myvuetable.tableData.map(item => this.getAssignedJourneys(item.id));
    },
    downloadReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_USER_GROUP_JOURNEY_REPORT}`,
        dateOnly: false,
        reportDownloadPayload: {
          group_id: itemId,
        },
      };
      this.$helpers.toggleModal(obj);
    },

    onAction(action, data) {
      const groupId = data.id;
      const groupName = data.name;
      this.idHolder = groupId;
      this.apiUrlHolder = API_USER_GROUP;

      if (action === 'delete-item' && !this.snotifyQueueList.includes(groupId)) {
        this.snotifyQueueList.push(groupId);
        this.$snotify.confirm(this.$t('group_delete_msg_body', { name: data.name }), this.$t('group_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }

      if (action === 'download-journey-report') {
        this.downloadReport(data);
      }

      if (action === 'edit-item') {
        const obj = {
          modalType: 'modalAddGroup',
          groupId,
          tabPane: 'mainTab',
          pageOptions: 'edit-name',
          editMode: true,
          pageToRefresh: 'Groups',
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'add-user') {
        const obj = {
          modalType: 'modalGroupAddUser',
          groupId,
          tabPane: 'mainTab',
          editMode: false,
          pageToRefresh: 'Groups',
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'add-journey') {
        const obj = {
          modalType: 'modalGroupAddJourney',
          groupId,
          tabPane: 'mainTab',
          pageToRefresh: 'Groups',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
      if (action === 'remove-journey') {
        const obj = {
          modalType: 'modalJourneyRemoveGroup',
          groupId,
          groupName,
          tabPane: 'mainTab',
          pageToRefresh: 'Groups',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
      if (action === 'notification') {
        const obj = {
          modalType: 'modalNotification',
          groupId,
          notificationType: 'group',
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'start-journey') {
        const obj = {
          modalType: 'modalSelectJourney',
          selectedToAssign: { // eslint-disable-next-line
            text: `[User Group] ${data.name}`,
            id: data.id,
            userGroup: true,
          },
          customerIdToEdit: this.$store.state.auth.customerId,
          launchedFrom: 'modalSelectJourney',
        };
        this.$helpers.toggleModal(obj);
      }
    },
  },
};
</script>
