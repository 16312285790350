<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ userName + '-' + $t('user_contents') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div class="modal-body">
            <div class="form-group">
              <div class="page">
                <div class="top-group">
                  <div>
                    <filter-perpage
                      @searchFor="setSearchFor"
                      @perPage="setPerPage"
                    />
                  </div>
                </div>

                <div class="container">
                  <div class="table-responsive">
                    <div
                      v-if="tableIsLoading"
                      class="lds-dual-ring"
                    />
                    <vue-table
                      :class="{ 'table-fade': tableIsLoading }"
                      ref="myvuetable"
                      :api-url="apiUrl"
                      :http-fetch="myFetch"
                      :api-mode="true"
                      :row-class="onRowClass"
                      @vuetable:loading="tableIsLoading = true"
                      @vuetable:loaded="tableIsLoading = false"
                      @vuetable:load-error="vuetableLoadError"
                      pagination-path="meta"
                      http-method="get"
                      :append-params="moreParams"
                      :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
                      :fields="fields"
                      :no-data-template="$t('no_data_info')"
                      :css="css.table"
                      :per-page="perPage"
                      :query-params="makeQueryParams"
                      @vuetable:pagination-data="onPaginationData"
                    >
                      <template
                        slot="type"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-center">
                          <span>
                            {{ $helpers.getSubTypeNameFromId(props.rowData.sub_type_id
                                                             , userLang) }}
                          </span>
                        </div>
                      </template>
                      <template
                        slot="activation_dt"
                        slot-scope="props"
                      >
                        {{ formatDate(props.rowData.activation_dt) }}
                      </template>
                      <template
                        slot="progress"
                        slot-scope="props"
                      >
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="calcRate(props.rowData) < 15 ? 'width:' + calcRate(props.rowData) + '%;color:#000' : 'width:' + calcRate(props.rowData) + '%;color:#fff'"
                            :aria-valuenow="calcRate(props.rowData)"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {{ '‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎' + calcRate(props.rowData) + '%' }}
                          </div>
                        </div>
                      </template>
                      <template
                        slot="first_viewed_at"
                        slot-scope="props"
                      >
                        {{ formatDate(props.rowData.first_viewed_at) }}
                      </template>
                      <template
                        slot="last_viewed_at"
                        slot-scope="props"
                      >
                        {{ formatDate(props.rowData.last_viewed_at) }}
                      </template>
                      <template
                        slot="completed_at"
                        slot-scope="props"
                      >
                        {{ formatDate(props.rowData.completed_at) }}
                      </template>
                      <template
                        slot="duration"
                        slot-scope="props"
                      >
                        {{ $helpers.convertToTimeString(props.rowData.duration) }}
                      </template>
                      <template
                        slot="email"
                        slot-scope="props"
                      >
                        <div
                          class="d-inline-block text-truncate"
                          style="max-width: 180px;"
                          :title="props.rowData.email"
                        >
                          {{ props.rowData.email }}
                        </div>
                      </template>
                      <!-- <template
                        slot="actions"
                        slot-scope="props"
                      >
                        <button
                          class="ui button delete"
                          @click="onAction('delete-item', props.rowData, props.rowIndex)"
                        >
                          <icon-container
                            :width="20"
                            :height="20"
                            view-box="0 0 24 24"
                            name="trash"
                            color="#727d92"
                          >
                            <icon-trash />
                          </icon-container>
                        </button>
                      </template> -->
                    </vue-table>
                    <div class="pagination__info">
                      <vuetable-pagination-info
                        :class="{ 'table-fade': tableIsLoading }"
                        ref="paginationInfo"
                        :info-template="$t('pagination_info')"
                        :no-data-template="$t('no_data_info')"
                      />
                      <div class="pagination__items">
                        <vuetable-pagination
                          ref="pagination"
                          :css="css.pagination"
                          @vuetable-pagination:change-page="onChangePage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="closeModal"
              >
                <span>
                  {{ $t('close') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapState } from 'vuex';
// import IconContainer from '@/components/elements/Icon.vue';
// import IconTrash from '@/components/icons/Trash.vue';
import moment from 'moment';

import VuetablePagination from '@/components/CustomVuetable/VuetablePagination.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import {
  API_REPORT_JOURNEY_V2_USER_CONTENTS,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import myTableMixin from '@/helpers/myTableMixin.vue';

export default {
  name: 'ModalReportJourneyV2UserContents',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        journey2_id: this.$store.state.modal.journey2_id,
        user_id: this.$store.state.modal.user_id,
        assigned_user_id: this.$store.state.modal.assigned_user_id,
      },
    };
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ModalReportJourneyV2UserContents') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
          this.$store.state.modal.launchedFrom = null;
        }, 500);
      }
    },
  },

  components: {
    // 'icon-container': IconContainer,
    // 'icon-trash': IconTrash,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    userName() {
      return this.$store.state.modal.user_name;
    },
    apiUrl() {
      return `${API_REPORT_JOURNEY_V2_USER_CONTENTS}`;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
    fields() {
      return [
        {
          name: 'name',
          title: this.$t('name'),
          sortField: 'name',
          width: '15%',
        },
        {
          name: 'type',
          title: this.$t('type'),
          sortField: 'sub_type_id',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'activation_dt',
          title: this.$t('activation_date'),
          sortField: 'activation_dt',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'progress',
          title: this.$t('completion_rate'),
          sortField: 'progress',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'first_viewed_at',
          title: this.$t('first_viewed_at'),
          sortField: 'first_viewed_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'last_viewed_at',
          title: this.$t('last_viewed_at'),
          sortField: 'last_viewed_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_at',
          title: this.$t('completed_at'),
          sortField: 'completed_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'duration',
          title: this.$t('duration'),
          sortField: 'duration',
          width: '20px',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'view_count',
          title: this.$t('view'),
          sortField: 'view_count',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '5%',
        },
      ];
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.$store.commit('modal/REFRESH_PAGE', false);
    },
    calcRate(rowdata) {
      let rating = 0;
      if (rowdata) {
        rating = parseInt(rowdata.progress, 10);
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(rating)) return 0;
      if (rating > 100) return 100;
      return rating;
    },
    formatDate(value) {
      let date = '-';
      if (value && value !== '-') {
        date = moment(value, API_SERVER_TIME_FORMAT).format(this.userLang === 'en' ? 'MM.DD.YYYY HH:mm' : 'DD.MM.YYYY HH:mm');
      }
      return date;
    },
    onRowClass(dataItem) {
      if (this.alreadyPastDate(dataItem.activation_dt)) {
        return 'past-date';
      }
      return 'future-date';
    },
    alreadyPastDate(value) {
      const targetDateTime = moment(value, 'YYYY-MM-DD HH:mm:ss');
      if (moment().isBefore(targetDateTime)) {
        return false;
      }
      return true;
    },
    makeQueryParams(sortOrder, currentPage, perPage) {
      // console.log('JSON:', JSON.stringify(sortOrder, null, 2));
      if (sortOrder.length > 0) {
        let sortString = sortOrder[0].sortField;
        if (sortString.endsWith('-slot')) {
          sortString = sortString.replace('-slot', '');
        }
        return {
          sort: sortString,
          order: sortOrder[0].direction,
          page: currentPage,
          per_page: perPage,
        };
      }
      return {
        sort: 'activation_dt',
        order: 'asc',
        page: currentPage ?? 1,
        per_page: this.perPage,
      };
    },
    onAction(action, data) {
      const userId = data.id;
      this.idHolder = userId;
    },
  },
};
</script>
<style scoped>
.modal .modal-body {
    padding: 15px 0px 30px;
    margin: 0 20px;
    min-width: max(80vw, 900px);

}
  .container {
      max-width: 98%;
      min-width: 1024px;
  }
  .disabled {
    opacity:0.5;
  }
  .top-group {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .vuetable tbody tr {
  height: 44px;
  }
::v-deep .future-date {
  opacity: 0.5;
}
::v-deeo .past-date {
  opacity: 1;
}

</style>
