<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div class="manager-user--title">
        <router-link
          :to="{
            name: 'Managers',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> {{ managerName }}<small> {{ $t('employee_development') }}</small>
      </div>
      <div class="table-responsive">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          v-if="userId"
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          :track-by="'1'"
          :show-pagination="false"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="tableHeader">
            <template>
              <tr>
                <th style="width:15%">
                  {{ this.$t('employee_name') }}
                </th>
                <th
                  style="width:15%"
                >
                  {{ this.$t('active_goal') }}
                  <svg-icon
                    v-tooltip.top="$t('info_active_goal')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:10%"
                >
                  {{ '1. ' + this.$t('critical_skills') }}
                  <svg-icon
                    v-tooltip.top="$t('info_critical_skills')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:10%"
                >
                  {{ '2. ' + this.$t('dev_plan') }}
                  <svg-icon
                    v-tooltip.top="$t('info_development_plan')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:10%"
                >
                  {{ '3. ' + this.$t('checkin') }}
                  <svg-icon
                    v-tooltip.top="$t('info_check_ins')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:10%"
                >
                  {{ '4. ' + this.$t('evaluation') }}
                  <svg-icon
                    v-tooltip.top="$t('info_evaluation')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:15%"
                >
                  {{ this.$t('timeline') }}
                  <svg-icon
                    v-tooltip.top="$t('info_timeline')"
                    type="mdi"
                    :path="iconInformationOutline"
                  />
                </th>
                <th
                  style="width:15%"
                >
                  {{ this.$t('sprints') }}
                </th>
              </tr>
            </template>
          </template>
          <template
            slot="slot_name"
            slot-scope="props"
          >
            <span class="slot_name">
              {{ props.rowData.name }}
            </span>
          </template>
          <template
            slot="slot_selected_skill"
            slot-scope="props"
          >
            <span class="slot_skill">
              {{ props.rowData.selected_skill | truncate }}
            </span>
          </template>
          <template
            slot="slot_identify_skill"
            slot-scope="props"
          >
            <span
              class="slot_td"
              :class="getBoxColor(props.rowData.step_1)"
            >
              {{ getBoxText(props.rowData.step_1) }}
            </span>
          </template>
          <template
            slot="slot_dev_plan"
            slot-scope="props"
          >
            <span
              class="slot_td"
              :class="getBoxColor(props.rowData.step_2)"
            >
              {{ getBoxText(props.rowData.step_2) }}
            </span>
          </template>
          <template
            slot="slot_checkin"
            slot-scope="props"
          >
            <span
              class="slot_td"
              :class="getCheckinColor(props.rowData)"
            >
              {{ getCheckinText(props.rowData) }}
            </span>
          </template>
          <template
            slot="slot_evaluation"
            slot-scope="props"
          >
            <span
              class="slot_td"
              :class="getBoxColor(props.rowData.step_4)"
            >
              {{ getBoxText(props.rowData.step_4) }}
            </span>
          </template>
          <template
            slot="slot_timeline"
            slot-scope="props"
          >
            <span
              class="slot_td"
            >
              <timeline-badge
                :end-date="props.rowData.end_dt"
                :start-date="props.rowData.sprint_start_dt"
              />
            </span>
          </template>
          <template
            slot="slot_sprints"
            slot-scope="props"
          >
            <span
              class="slot_td"
            >
              <sprint-badge :rate="props.rowData.sprint_count" />
            </span>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';


import { directive as onClickaway } from 'vue-clickaway';

import AppHeader from '@/components/AppHeader.vue';
import IconContainer from '@/components/elements/Icon.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import SprintBadge from '@/components/SprintBadge.vue';
import TimelineBadge from '@/components/TimelineBadge.vue';
import {
  API_GET_MANAGER_DEV_REPORT,
} from '@/helpers/config';
import myTableMixin from '@/helpers/myTableMixin.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiInformationOutline } from '@mdi/js';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ManagerUserDevReport',
  mixins: [myTableMixin],
  data() {
    return {
    };
  },
  components: {
    'icon-container': IconContainer,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
    'sprint-badge': SprintBadge,
    'timeline-badge': TimelineBadge,
    SvgIcon,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  computed: {
    iconInformationOutline() {
      return mdiInformationOutline;
    },
    userId() {
      return this.$route.params.userId;
    },
    managerName() {
      return this.$route.params?.title;
    },
    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
        {
          text: this.$t('managers'),
          url: '/managers',
        },
        {
          text: this.$t('buddies'),
          url: '/buddies',
        },
      ];
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    apiUrl() {
      if (API_GET_MANAGER_DEV_REPORT && this.userId) {
        return `${API_GET_MANAGER_DEV_REPORT}/${this.userId}`;
      }
      return null;
    },

    fields() {
      return [
        {
          name: 'slot_name',
          title: this.$t('employee_name'),
          width: '16%',
        },
        {
          name: 'slot_selected_skill',
          title: this.$t('active_goal'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_identify_skill',
          title: this.$t('identify_skills'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_dev_plan',
          title: this.$t('dev_plan'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_checkin',
          title: this.$t('checkin'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_evaluation',
          title: this.$t('evaluation'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_timeline',
          title: this.$t('timeline'),
          width: '12%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'slot_sprints',
          title: this.$t('sprints'),
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ];
    },
  },
  filters: {
    truncate(input) {
      return input.length > 21 ? `${input.substring(0, 21)}...` : input;
    },
  },

  methods: {
    getBoxColor(step) {
      if (step) {
        if (step.status === 1) {
          return 'green';
        }
        if (step.status === -1) {
          return 'darkgray';
        }
        if (step.status === 0) {
          const now = moment();
          const deadline = moment(step.deadline_dt, 'YYYY-MM-DD HH:mm:ss');
          const diff = now.diff(deadline, 'days');
          if (diff > 0) {
            return 'red';
          }
          return 'theyellow';
        }
      }
      return 'darkgray';
    },

    getBoxText(step) {
      if (step) {
        if (step.status === 1) {
          return this.$t('done');
        }
        if (step.status === 0) {
          const now = moment();
          const deadline = moment(step.deadline_dt, 'YYYY-MM-DD HH:mm:ss');
          const isAlreadyPast = deadline.isBefore(now);
          const diff = Math.abs(now.diff(deadline, 'days'));
          // console.log('diff: ', diff, isAlreadyPast);
          if (isAlreadyPast) {
            return this.$tc('start_overdue', diff, { day: diff });
          }
          return this.$tc('start_left', diff, { day: diff });
        }
        if (step.status === -1) {
          return '';
        }
      }
      return '';
    },
    getParsedName(rawname) {
      if (rawname) {
        return rawname.split(' ')[0];
      }
      return '';
    },
    getCheckinColor(content) {
      const key = content && content.step_3 && content.step_3.message ? content.step_3.message : null;
      let color = 'darkgray';

      switch (key) {
        case 'S1-P3-K1':
        case 'S1-P0-K2':
          color = 'thegray';
          break;
        case 'S2-P0-K2':
          color = 'red';
          break;
        case 'S3-P0-K2':
          color = 'theyellow';
          break;
        case 'S4-P0-K2':
          color = 'green';
          break;
        case 'S1-P1-K1':
        case 'S1-P1-K2':
          color = 'red';
          break;
        case 'S1-P2-K2':
          color = 'theyellow';
          break;
        case 'S1-P3-K2':
          color = 'green';
          break;
        case 'S2-P1-K1':
        case 'S2-P1-K2':
          color = 'red';
          break;
        case 'S2-P2-K1':
        case 'S2-P2-K2':
          color = 'red';
          break;
        case 'S2-P3-K1':
        case 'S2-P3-K2':
          color = 'red';
          break;
        case 'S3-P3-K1':
          color = 'theyellow';
          break;
        case 'S3-P1-K2':
          color = 'theyellow';
          break;
        case 'S3-P2-K2':
          color = 'theyellow';
          break;
        case 'S3-P3-K2':
          color = 'theyellow';
          break;
        case 'S3-P1-K1':
          color = 'theyellow';
          break;
        case 'S3-P2-K1':
          color = 'theyellow';
          break;
        case 'S4-P3-K1':
          color = 'green';
          break;
        case 'S4-P1-K2':
          color = 'green';
          break;
        case 'S4-P2-K2':
          color = 'green';
          break;
        case 'S4-P3-K2':
          color = 'green';
          break;
        default:
          break;
      }

      return color;
    },

    getCheckinText(content) {
      if (content && content.step_3 && content.step_3.message) {
        const name = this.getParsedName(content.name);
        const num = content.step_3 && content.step_3.x ? content.step_3.x : null;
        const msg = this.$t(content.step_3.message, { name, num });
        // if (truncated && msg.length > 30) msg = `${msg.substring(0, 30)}...`;
        return msg;
      }

      return '';
    },

    listDayFormat(date) {
      return moment(date).locale(this.userLang === 'en' ? 'en' : 'tr').format(this.userLang === 'en' ? 'MMM. DD' : 'DD MMM.');
    },
  },
};
</script>

<style lang="scss" scoped>
  .manager-user--title {
    display: flex;
    font-size: 16px;
    align-items: center;
    color: #727D92;
    font-weight: 500;
    margin-bottom: 10px;
    small {
      font-size: 16px;
      margin-left: 10px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }

  ::v-deep .vuetable {
    border-collapse: collapse;
    border-spacing: 1px;
    tbody {
      tr {
        box-shadow: none;
        height: auto;
        min-height: 40px;
        td {
          padding:2px;
          background: transparent;
          span {
            display: flex;
            height: auto;
            width: 100%;
            min-height: 40px;
            padding: 5px;
          }
          .slot_name {
            justify-content: left;
            align-items: center;
            background: #F5F6F8;
            color: #6C6C6C;
            line-height: 1;
            border-bottom: 1px solid #E6E6E6;
            border-right: 1px solid #E6E6E6;
            &::before {
            content: " ";
              display: inline-block;
              background: #2750FA;
              height:100%;
              min-height: 40px;
              width: 10px;
              margin: -5px 5px -5px -5px;
            }
          }
          .slot_skill {
            justify-content: center;
            align-items: center;
            background: #F5F6F8;
            color: #6C6C6C;
            line-height: 1;
            border-bottom: 1px solid #E6E6E6;
            border-right: 1px solid #E6E6E6;
          }
          .slot_td {
            justify-content: center;
            align-items: center;
            background: #F5F6F8;
            color: #6C6C6C;
            line-height: 1;
            border-bottom: 1px solid #E6E6E6;
            border-right: 1px solid #E6E6E6;
            &.lightgray {
              background:#F1F1F1;
              color: #6C6C6C;
            }
            &.yellow {
              background: #F1B15C;
              color: #FFFFFF;
            }
            &.lightgray {
              background:#F1F1F1;
              color: #6C6C6C;
            }
            &.green {
              background: #5AC47D;
              border-bottom: 1px solid #2f834b;
              border-right: 1px solid #2f834b;
              color: #fff;
              a {
                  color: #fff;
                  font-weight:700;
              }
            }
            &.red {
              background: #D1515F;
              border-bottom: 1px solid #b6303f;
              border-right: 1px solid #b6303f;
              color: #fff;
              a {
                  color: #fff;
                  font-weight:700;
              }
            }
            &.theyellow {
              background: #FDAB3D;
              border-bottom: 1px solid #e38302;
              border-right: 1px solid #e38302;
              color: #fff;
              a {
                  color: #fff;
                  font-weight:700;
              }
            }
            &.thegray {
              background: #F5F6F8;
              border-bottom: 1px solid #F5F1F1;
              border-right: 1px solid #F5F1F1;
              color: #fff;
              a {
                  color: #333;
                  font-weight:500;
              }
            }
            &.darkgray {
              background: #D5D5D5;
              border-bottom: 1px solid #bfbfbf;
              border-right: 1px solid #bfbfbf;
              color: #333;
              a {
                  color: #333;
                  font-weight:500;
              }
            }
            &.colorless {
              background: transparent;
              color: #6C6C6C;
            }
            &.white {
              background: #fff;
              color: #6C6C6C;
            }
          }
        }
      }
    }
  }
  th svg {
    height: 18px;
    width: 18px;
  }
</style>
